import React from "react";
import { useSelector } from "react-redux";
const FeatureSection3 = () => {
  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.featureSection);
  return (
    <>
      <section className="section-sp1 service-wraper2">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-sm-6 mb-30">
              <div className="feature-container feature-bx3">
                <h2 className="counter text-secondary">120</h2>
                <h5 className="ttr-title">{main.card1.heading['ar']}</h5>
                <p>
                  {main.card1.text['ar']}
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-30">
              <div className="feature-container feature-bx3">
                <h2 className="counter text-secondary">400</h2>
                <h5 className="ttr-title">{main.card2.heading['ar']}</h5>
                <p>
                  {main.card2.text['ar']}
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-30">
              <div className="feature-container feature-bx3">
                <h2 className="counter text-secondary">250</h2>
                <h5 className="ttr-title">{main.card3.heading['ar']}</h5>
                <p>
                 {main.card3.text['ar']}
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-30">
              <div className="feature-container feature-bx3">
                <h2 className="counter text-secondary">800</h2>
                <h5 className="ttr-title">{main.card4.heading['ar']}</h5>
                <p>
                  {main.card3.text['ar']}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureSection3;
