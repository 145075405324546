import React from "react";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutSection from "../elements/about";
import LatestNewsSection from "../elements/latestNewsSlider";
import FeatureSection3 from "../elements/featureSection3";
import TeamSection from "../elements/team";
import TestimonialSection from "../elements/testimonial";

import HeroPage from "../elements/heroPage";

import { useSelector } from "react-redux";

const Aboutus = () => {
  const lang = 'ar';
  const { about } = useSelector((state) => state.lang.value.pages);
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <HeroPage text={about['ar']} />

        <AboutSection />

        <FeatureSection3 />

        <TeamSection />

        <TestimonialSection />

        <LatestNewsSection />
      </div>

      <Footer />
    </>
  );
};

export default Aboutus;
