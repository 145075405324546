import React from "react";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutSection from "../elements/about";
import WorkSection from "../elements/work";
import AppointmentSection from "../elements/appointment";
import TestimonialSection from "../elements/testimonial";
import ServicesSliderSection from "../elements/servicesSlider";
import LatestNewsSection from "../elements/latestNewsSlider";
import MainBanner from "../elements/mainBanner";
import Team from '../elements/team'
import ContactSection from "../elements/ContactSection";
import OurClients from "../elements/ourClients";

const Index = () => {

  return (
    <>
      <Header />

      <MainBanner />

      <AboutSection />

      {/* <WorkSection /> */}

      <AppointmentSection />

      <ServicesSliderSection />

      <Team />
      
      {/* <TestimonialSection /> */}

      <LatestNewsSection />

      <OurClients />
      
      <ContactSection />

      <Footer />
    </>
  );
};

export default Index;
