import React from "react";
import { useSelector } from "react-redux";
const WidgetSearch = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const main = useSelector((state) => state.lang.value.blogDetailsRight);
  return (
    <>
      <div className="widget widget_search">
        <form className="searchform">
          <div className="input-group">
            <input
              name="text"
              className="form-control"
              placeholder={main.input[lang]}
              type="text"
            />
            <div className="input-group-btn">
              <button type="submit" className="btn btn-secondary">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default WidgetSearch;
