import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import servicesPic1 from "../../images/Physiotherapy-Rooms.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";

const ServicesDetailsBoon = () => {

    const lang = 'ar';
    const main = useSelector((state) => state.lang.value.servicesDetails);
    const mainRight = useSelector((state) => state.lang.value.servicesDetailsRight);
    const { servicesDetails } = useSelector((state) => state.lang.value.pages)

    return (
        <>
            <Header />

            <div className="page-content bg-white">
                <HeroPage text={servicesDetails[lang]} />

                <section className="section-area section-sp1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-30">
                                <div className="ttr-media mb-30 ">
                                    <img src={servicesPic1} className="rounded w-full" alt="" />
                                </div>
                                <div className="clearfix">
                                    <div className="head-text mb-30">
                                        <h2 className="title mb-15">
                                            قسم جراحة العظام
                                        </h2>
                                        <p className="fw-bold mb-0">
                                            تتميز المستشفى بفريق طبى ذو خبرة عالية للتعامل مع مشاكل العظام والمفاصل وما يتبع ذلك من إعادة التأهيل وتتعدد الخدمات العلاجية المقدمة من القسم وتشمل الخدمات التالية:
                                        </p>
                                        <ul>
                                            <li>جميع أنواع ا?صابات والكسور بما فى ذلك إصابات العمود الفقرى.</li>
                                            <li>ا?صابات الرياضية بما في ذلك الركبة-الكاحل- الكتف -وغيرها من المفاصل.</li>
                                            <li>العمود الفقرى مثل ألام الضهر-مشاكل الفقرات -أورام وإلتهابات الحبل الشوكى وضيق القناة الشوكية.</li>
                                        </ul>


                                        <p className="fw-bold mb-0">داء المفاصل التنكسى الذي يتطلب استبدال المفاصل ويشمل:</p>
                                        <ul className="mt-0">
                                            <li>استبدال مفصل الفخذ</li>
                                            <li>استبدال مفصل الركبة</li>
                                            <li>استبدال مفصل الكتف</li>
                                        </ul>
                                        <p className="fw-bold mb-0">جراحة اليد:</p>
                                        <ul>
                                            <li>إعادة توصيل اعصاب بالجراحة الميكروسكوبية/إصابات الضفيرة العضدية(المرتبطة بالولادة أو نتيجة للارتطام).</li>
                                            <li>التشوهات الخلفية لليد.</li>
                                            <li>عيوب اليد الرضية وغير الرضية.</li>
                                            <li>إصلاح اوتار وإعادة توصيلها.</li>
                                            <li>إصلاح اربطة وإعادة توصيلها.</li>

                                        </ul>
                                        <p className="fw-bold mb-0">جراحة العظام للأطفال:</p>
                                        <ul>
                                            <li> جميع إصابات اﻷطفال.</li>
                                            <li>المشاكل الخلقية للفخذين والقدمين.</li>
                                        </ul>
                                        <p className="fw-bold mb-0">جراحات مناظير:</p>
                                        <ul>
                                            <li>*الركبة (إصلاح الغضروف الهلالى -إصلاح الاربطة -تنضيف مفصل الركة).
                                            </li>
                                            <li>*الكاحل (اﻹلتهاب العضمى الغضروفى كلحل لاعب كرة القدم).
                                            </li>
                                            <li>*مفصل الفخذ (متلازمة اﻹعاقة الوظيفية في حركة مفصل الفخذ).
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <aside className="sticky-top pb-1">
                                    <div className="widget">
                                        <ul className="service-menu">
                                            <li className="">
                                                <Link to="/service-detail-emergency">
                                                    <span>خدمات الطوارئ</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-surgery">
                                                    <span>الجراحة وغرف العمليات</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-outpatient-clinic">
                                                    <span>العيادات الخارجية</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-woman">
                                                    <span>النساء والولادة وعلاج العقم وتأخر الانجاب</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-baby">
                                                    <span>طب الاطفال وحديثى الولادة</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <Link to="/service-detail-boon">
                                                    <span>جراحة العظام</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-urinary-tract">
                                                    <span>المسالك البولية </span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-physical-therapy">
                                                    <span>العلاج الطبيعى</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-diagnostic-x-ray">
                                                    <span>اشعة التشخيصية</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-lab">
                                                    <span>المعمل</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="widget">
                    <div className="brochure-bx">
                      <h5 className="title-head">{mainRight.downloadHeading[lang]}</h5>
                      <Link to="#" className="download-link">
                        <img src={pdf} alt="" />
                        <h5 className="title">{mainRight.file1[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                      <Link to="#" className="download-link">
                        <img src={doc} alt="" />
                        <h5 className="title">{mainRight.file2[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                    </div>
                  </div> */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </>
    );
};

export default ServicesDetailsBoon;
