import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import servicesPic1 from "../../images/Surgery.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";

const ServicesDetailsSurgery = () => {

  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.servicesDetails);
  const mainRight = useSelector((state) => state.lang.value.servicesDetailsRight);
  const { servicesDetails } = useSelector((state) => state.lang.value.pages)

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <HeroPage text={servicesDetails[lang]} />

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-30">
                <div className="ttr-media mb-30 ">
                  <img src={servicesPic1} className="rounded w-full" alt="" />
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h2 className="title mb-15">
                      قسم الجراحه وغرف العمليات
                    </h2>
                    <p className="">
                      يمتلك القسم مجموعة شامله من الادوات الجراحية لإجراء الجراحات المفتوحة وجراحات الالمناظير ومنظار المعدة وجراحات الليزر باﻹضافه إلى أحدث المجاهر الجراحية كما يتمتع القسم بالقدرة على إجراء جميع أنواع اﻹجراءات الجراحية .                   </p>

                    <p className="fw-bold">يوفر قسم الجراحة خدمات الرعاية الصحية والاستشارات والخدمات الجراحية للحالات الطارئة والحالات المجدولة مسبقا وذلك في المجالات الجراحية التخصصية والمجالات الثانوية ويقدم القسم خدماته للمرضى من الجنسين في الجنسين في التخصصات التالية:</p>
                  <ul>
                    <li>الجراحة العامة</li>
                    <li>الجراحة التجميلية</li>
                    <li>جراحة العظام</li>
                    <li>جراحة المسالك البولية</li>

                  </ul>
                  </div>
                  {/* <div className="row align-items-center">
                    <div className="col-md-6 mb-30">
                      <ul className="list-check-squer mb-0">
                        <li>{main.Detail1[lang]}</li>
                        <li>{main.Detail2[lang]}</li>
                        <li>{main.Detail3[lang]}</li>
                        <li>{main.Detail4[lang]}</li>
                        <li>{main.Detail5[lang]}</li>
                      </ul>
                    </div>
                    <div className="col-md-6 mb-30">
                      <div className="skillbar-box mb-30">
                        <h6 className="title">{main.progress1[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "96%" }}
                          ></p>
                          <span className="skill-bar-percent">79%</span>
                        </div>
                      </div>
                      <div className="skillbar-box mb-30">
                        <h6 className="title">{main.progress2[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "79%" }}
                          ></p>
                          <span className="skill-bar-percent">79%</span>
                        </div>
                      </div>
                      <div className="skillbar-box mb-0">
                        <h6 className="title">{main.progress3[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "75%" }}
                          ></p>
                          <span className="skill-bar-percent">75%</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    
                  </div>

                <p className="fw-bold">غرفة العمليات الرئيسية</p>
                <p className="mb-0">غرفة العمليات الرئيسية في مستشفى العجوز التخصصى هي وحدة متعددة التخصصات يتم فيها مجموعة واسعة من اﻹجراءات الجراحية بدء من العمليات الجراحية الصغرى ووصولا إلى العمليات الجراحية الكبرى وتشتمل هذه الوحدة على عدة غرف عمليات لتقديم الخدمات للمرضى الذين تتراوح أعمارهم ما بين شهر إلى 14 عاما لﻷطفال والبالغين فوق 15عاما.</p>
                <p className="mb-0">وأهم ما يميز غرف عمليات مستشفى العجوز التخصصى انها معقمه بأعلى أنظمة التعقيم لضمان اعلى مستويات الامان والجودة.</p>
                <p>ويتمثل هدفنا في توفير أفضل رعاية طبية ممكنة للمرضى في جميع اﻷوقات وعلى مدار الساعة وطيلة أيام اﻷسبوع ولدينا فريق متخصص مكون من اكثر الاطباء خبرة في مختلف التخصصات حيث يقدمون خدماتهم بالقسم لما يتراوح بين 150-300 مريض شهريا.</p>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="sticky-top pb-1">
                  <div className="widget">
                    <ul className="service-menu">
                      <li className="">
                        <Link to="/service-detail-emergency">
                          <span>خدمات الطوارئ</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li className="active">
                        <Link to="/service-detail-surgery">
                          <span>الجراحة وغرف العمليات</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li >
                        <Link to="/service-detail-outpatient-clinic">
                          <span>العيادات الخارجية</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-woman">
                          <span>النساء والولادة وعلاج العقم وتأخر الانجاب</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-baby">
                          <span>طب الاطفال وحديثى الولادة</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-boon">
                          <span>جراحة العظام</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-urinary-tract">
                          <span>المسالك البولية </span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-physical-therapy">
                          <span>العلاج الطبيعى</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-diagnostic-x-ray">
                          <span>اﻷشعة التشخيصية</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-lab">
                          <span>المعمل</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="widget">
                    <div className="brochure-bx">
                      <h5 className="title-head">{mainRight.downloadHeading[lang]}</h5>
                      <Link to="#" className="download-link">
                        <img src={pdf} alt="" />
                        <h5 className="title">{mainRight.file1[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                      <Link to="#" className="download-link">
                        <img src={doc} alt="" />
                        <h5 className="title">{mainRight.file2[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                    </div>
                  </div> */}
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default ServicesDetailsSurgery;
