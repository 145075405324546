import React from 'react';


import { useSelector } from 'react-redux';


const CommentRespond = () => {
	const lang = 'ar';
	const main = useSelector((state) => state.lang.value.blogDetails);
  return (
	<>
	<div className="comment-respond" id="respond">
		<h4 className="widget-title">{main.replay.heading['ar']}</h4>
		<form className="comment-form" id="commentform">
			<p className="comment-form-author">
				<label htmlFor="author">Name <span className="required">*</span></label>
				<input type="text" value="" name="Author"  placeholder={main.replay.author['ar']} id="author"/>
			</p>
			<p className="comment-form-email">
				<label htmlFor="email">Email <span className="required">*</span></label>
				<input type="text" value="" placeholder={main.replay.email['ar']} name="email" id="email"/>
			</p>
			<p className="comment-form-comment">
				<label htmlFor="comment">Comment</label>
				<textarea rows="8" name="comment" placeholder={main.replay.comment['ar']} id="comment"></textarea>
			</p>
			<p className="form-submit">
				<input type="submit" value={main.replay.button['ar']} className="submit" id="submit" name="submit"/>
			</p>
		</form>
	</div>
	
</>
  )
}

export default CommentRespond
