import React from "react";
import { Link } from "react-router-dom";

// Images
import Logo from "../../images/logo.png";
import ptImg1 from "../../images/shap/wave-blue.png";
import ptImg2 from "../../images/shap/circle-dots.png";
import ptImg3 from "../../images/shap/plus-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";

// Social Images
import facebook from "../../images/social/facebook.png";
import instagram from "../../images/social/instagram.png";
import youtube from "../../images/social/youtube.png";
import { useSelector } from "react-redux";

const Footer = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const footer = useSelector((state) => state.lang.value.footer);
  const quickLinks = useSelector((state) => state.lang.value.quickLinks);
  const ourServices = useSelector((state) => state.lang.value.ourServices);
  const subscribe = useSelector((state) => state.lang.value.subscribe);
  return (
    <>
      <footer
        className="footer"
      >
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="widget widget_info">
                  <div className="footer-logo">
                    <Link to="/" className="">
                      <img src={Logo} alt="" style={{ width: "70px" }} />
                      <h2 className="footer-title d-inline-block mx-2">
                        {footer.title[lang]}
                      </h2>
                    </Link>
                  </div>
                  <div className="ft-contact">
                    <p>{footer.text[lang]}</p>
                    <div className="contact-bx">
                      <div className="icon">
                        <i className="fas fa-phone-alt"></i>
                      </div>
                      <div className="contact-number mx-1">
                        <span>{footer.contact[lang]}</span>
                        <h4 className="number">01111149544</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-6">
                <div className="widget footer_widget ml-50">
                  <h3 className="footer-title">{quickLinks.name[lang]}</h3>
                  <ul>
                    <li>
                      <Link to="/about-us">
                        <span>{quickLinks.about[lang]}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services">
                        <span>{quickLinks.services[lang]}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/booking">
                        <span>{quickLinks.booking[lang]}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq">
                        <span>{quickLinks.faq[lang]}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog-grid">
                        <span>{quickLinks.blogs[lang]}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/team">
                        <span>{quickLinks.ourTeam[lang]}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-6">
                <div className="widget footer_widget">
                  <h3 className="footer-title">{ourServices.name[lang]}</h3>
                  <ul>
                    <li>
                      <Link to="/service-detail-emergency">
                        <span>الطوارئ</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-detail-surgery">
                        <span>جراحة وغرف عمليات</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-detail-outpatient-clinic">
                        <span>العيادات الخارجية</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-detail-woman">
                        <span>نسا وتوليد</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-detail-physical-therapy">
                        <span>علاج طبيعى</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service-detail-baby">
                        <span>أطفال وحديثى ولادة</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="widget widget_form">
                  <h3 className="footer-title">{subscribe.name[lang]}</h3>
                  <form className="subscribe-form subscription-form mb-30">
                    <div className="ajax-message"></div>
                    <div className="input-group">
                      <input
                        name="email"
                        required="required"
                        className="form-control"
                        placeholder={subscribe.input[lang]}
                        type="email"
                      />
                    </div>
                    <button
                      name="submit"
                      value="Submit"
                      type="submit"
                      className="btn btn-secondary shadow w-100"
                    >
                      {subscribe.button[lang]}
                    </button>
                  </form>
                  <div className="footer-social-link">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.facebook.com/Elagouz.Hospital/"
                        >
                          <img src={facebook} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://instagram.com/elagouz_hospital?igshid=YmMyMTA2M2Y="
                        >
                          <img src={instagram} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://youtube.com/channel/UCZqQMivKhy2gKcMCq3X6WdQ"
                        >
                          <img src={youtube} alt="" width={"30px"} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="row">
              <div className="col-12 text-center">
                <p className="copyright-text">
                  Copyright © 2022 Design & Developed by{" "}
                  <a
                    href="https://cyparta.com/"
                    rel="noreferrer"
                    target="_blank"
                    className="text-secondary"
                  >
                    Cyparta
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
        <img className="pt-img2 animate1" src={ptImg2} alt="" />
        <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
        <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
      </footer>
    </>
  );
};

export default Footer;
