import axios from "axios";

export const getServicesThunk = async (data, ThunkApi) => {
  try {
    const res = await axios.get(
      "https://landingpage-app-92j29.ondigitalocean.app/services/4"
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getProvidersThunk = async (id) => {
  try {
    const res = await axios.get(
      `https://landingpage-app-92j29.ondigitalocean.app/appointements/api/providers/4/?service=${id}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getOpenThunk = async (id, ThunkApi) => {
  try {
    const res = await axios.get(
      `https://landingpage-app-92j29.ondigitalocean.app/appointements/api/open/noauth/4/?providerid=${id.doctor}&serviceid=${id.service}`
    );
    return res
  } catch (error) {
    // if (error.status !== 200) {
    //   console.log(error)
    //   return ThunkApi.rejectWithValue(
    //     "There is no open appoinetemnts for this provider coressponding to serviceid"
    //   );
    // }
    if (!error.response) {
      throw error
    }
    return ThunkApi.rejectWithValue(
      "There is no open appoinetemnts for this provider coressponding to serviceid"
    );
  }
};

export const bookAppointThunk = async ({ data, open }) => {
  try {
    const res = await axios.patch(
      `https://landingpage-app-92j29.ondigitalocean.app/appointements/api/book/noauth/${open}/4/`,
      { ...data }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
