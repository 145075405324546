import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const initialState = {
  form: [],
  loading: false,
  status: "false",
};

// register user
export const contactUser = createAsyncThunk(
  "contact/contactUser",
  async (data, ThunkApi) => {
    try {
      const res = await axios.post(
        "https://landingpage-app-92j29.ondigitalocean.app/main/api/contact/",
        data
      );
      return res;
    } catch (error) {
      console.log(error)
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers:  (builder) => {
    builder.addCase("contact/contactUser/pending", (state, action) => {
      state.loading = true
    })
    builder.addCase(contactUser.fulfilled, (state, action) => {
      state.loading = false
      state.status = action.payload.statusText;
      state.form = action.meta.arg
      toast.success('Mail sent successfully', {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    })
  },
});

// Action creators are generated for each case reducer function

export default contactSlice.reducer;
