import { createAsyncThunk } from "@reduxjs/toolkit";

export const PostFamilyCard = createAsyncThunk(
    "/familyCart",
    async (data, { getState, rejectWithValue, dispatch }) => {
        console.log(data);
        try {
            const response = await fetch(`https://landingpage-app-92j29.ondigitalocean.app/main/api/family-card/`, {
                method: "POST",
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                //     'Access-Control-Allow-Origin': '*',
                // },
                body: data,
            });
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const PostFamilyCardMembers = createAsyncThunk(
    "/PostFamilyCardMembers",
    async (data, { getState, rejectWithValue, dispatch }) => {
        console.log(data);
        try {
            const response = await fetch(`https://landingpage-app-92j29.ondigitalocean.app/main/api/family-card-members/`, {
                method: "POST",
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                //     'Access-Control-Allow-Origin': '*',
                // },
                body: data,
            });
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
