import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    lang: localStorage.getItem("lang")
      ? JSON.parse(localStorage.getItem("lang"))
      : "ar",
    mainBannerSection: {
      text: {
        en: "An integrated medical facility and comprehensive care for you and your family",
        ar: "صرح طبي متكامل ورعاية شاملة ليك ولأسرتك",
      },
      heading: {
        en: "El Agouz Specialized Hospital includes a group of the best doctors specialists at the highest level in all specialties",
        ar: "مستشفى العجوز التخصصى تضم نخبة من أفضل الأطباء إستشاريين وأخصائيين على أعلى مستوى فى جميع التخصصات ",
      },
      button: {
        en: "Book an appoinement",
        ar: "حجز موعد",
      },
    },
    aboutSection: {
      heading: {
        en: "About Us",
        ar: "معلومات عنا",
      },
      subHeading: {
        en: "El Agouz Specialized Hospital",
        ar: "مستشفى العجوز التخصصى",
      },
      text: {
        en: "Al-Agouz Hospital provides excellent medical services with outpatient and inpatient clinics in many specialties and departments.",
        ar: "تقدم مستشفى العجوز خدمات طبية ممتازة مع العيادات الخارجية والمرضى الداخليين في العديد من التخصصات والأقسام. ",
      },
      icon: {
        en: "Emergency Help",
        ar: "مساعدة الطوارئ",
      },
      icon2: {
        en: "Qualified Doctors",
        ar: "أطباء مؤهلين",
      },
      icon3: {
        en: "Best Professionals",
        ar: "أفضل المحترفين",
      },
      icon4: {
        en: "Medical Treatment",
        ar: "العلاج الطبي",
      },
      button: {
        en: "Read More",
        ar: "قراءة المزيد",
      },
    },
    workSection: {
      title: {
        en: "Working Process",
        ar: "عملية العمل",
      },
      heading: {
        en: "How we works?",
        ar: "كيف نعمل؟",
      },
      card1: {
        heading: {
          en: "Make Appointmnet",
          ar: "",
        },
        text: {
          en: "It is a long established fact that a reader will be distracted by the readable content of.",
          ar: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لـ.",
        },
        button: {
          en: "Read More",
          ar: "قراءة المزيد",
        },
      },
      card2: {
        heading: {
          en: "Take Treatment",
          ar: "خذ العلاج",
        },
        text: {
          en: "It is a long established fact that a reader will be distracted by the readable content of.",
          ar: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لـ.",
        },
        button: {
          en: "Read More",
          ar: "قراءة المزيد",
        },
      },
      card3: {
        heading: {
          en: "Registration",
          ar: "تسجيل",
        },
        text: {
          en: "It is a long established fact that a reader will be distracted by the readable content of.",
          ar: "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لـ.",
        },
        button: {
          en: "Read More",
          ar: "قراءة المزيد",
        },
      },
    },
    appointmentSection: {
      title: {
        en: "book",
        ar: "حجز",
      },
      selectDepartment: {
        title: {
          en: "selectDepartment",
          ar: "حدد القسم",
        },
        one: {
          en: "one",
          ar: "واحد",
        },
        two: {
          en: "two",
          ar: "واحد",
        },
        three: {
          en: "three",
          ar: "واحد",
        },
      },
      selectDoctor: {
        selectDoctor: {
          ar: "اختر الطبيب",
          en: "select doctor",
        },
        one: {
          en: "one",
          ar: "واحد",
        },
        two: {
          en: "two",
          ar: "واحد",
        },
        three: {
          en: "three",
          ar: "واحد",
        },
      },
      name: {
        en: "Your Name",
        ar: "اسمك",
      },
      phone: {
        en: "Phone Numbers",
        ar: "أرقام الهواتف",
      },
      button: {
        en: "Appointment Now",
        ar: "احجز الآن",
      },
    },
    servicesSliderSection: {
      title: {
        en: "Services",
        ar: "خدمات",
      },
      heading: {
        en: "We Cover A Big Variety Of Medical Services",
        ar: "نحن نغطي مجموعة كبيرة ومتنوعة من الخدمات الطبية",
      },
      text: {
        en: "We provide the special tips and advice’s of heath care treatment and high level of best.",
        ar: "نحن نقدم النصائح والنصائح الخاصة لعلاج الرعاية الصحية والمستوى العالي من الأفضل.",
      },
      button: {
        en: "All Services",
        ar: "جميع الخدمات",
      },
      card1: {
        heading: {
          en: "Operations department",
          ar: " العمليات",
        },
        text: {
          en: "3 operating rooms to receive all specialties and operating room equipment according to the highest specifications",
          ar: "عدد 3 غرف عمليات  لاستقبال جميع التخصصات وتجهيزات غرف العمليات تبعا لاعلي المواصفات",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card2: {
        heading: {
          en: "الحضانة",
          ar: "الحضانة",
        },
        text: {
          en: "Phasellus venenatis porta rhoncus. Integer et viverra felis.",
          ar: "علي اعلي مستوي من التجهيزات ويستخدم في الجراحة العامة وعمليات المسالك البولية وجراحة العظام . عمليات الأوعية الدموية ",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card3: {
        heading: {
          en: "Outpatient department",
          ar: "قسم العيادات الخارجية",
        },
        text: {
          en: "This department plays a vital role in providing medical care to all segments of society, committed to the highest standards of quality and safety",
          ar: "يؤدى هذا القسم دوراً حيوياً فى تقديم الرعاية الطبية لجميع شرائح المجتمع ملتزماً بأعلى معايير الجودة و السلامة",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card4: {
        heading: {
          en: "Internal department",
          ar: " العلاج الطبيعى كبار-اطفال",
        },
        text: {
          en: "All hospital rooms are designed and equipped to the highest standard",
          ar: "جميع غرف المستشفى مصممة ومجهزة على أعلى مستوى",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card5: {
        heading: {
          en: "Outpatient department",
          ar: "  تحاليل",
        },
        text: {
          en: "This department plays a vital role in providing medical care to all segments of society, committed to the highest standards of quality and safety",
          ar: "يؤدى هذا القسم دوراً حيوياً فى تقديم الرعاية الطبية لجميع شرائح المجتمع ملتزماً بأعلى معايير الجودة و السلامة",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card6: {
        heading: {
          en: "Emergency department",
          ar: "قسم الطوارئ",
        },
        text: {
          en: "The Emergency Department at Al-Agouz Specialist Hospital is a highly efficient and professional unit, as it provides 24-hour care to patients for all types of medical emergencies. The emergency unit operates 24 hours a day, 7 days a week.",
          ar: "يعتبر قسم الطوارئ في مستشفى العجوز التخصصي وحدة عالية الكفاءة واحترافية، إذ توفر الرعاية للمرضى على مدار ال24 ساعة ولكافة أنواع حالات الطوارئ الطبية. تعمل وحدة الطوارئ على مدار 24 ساعة وطوال أيام الأسبوع، ",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card7: {
        heading: {
          en: " sonar",
          ar: "سونار",
        },
        text: {
          en: "This department plays a vital role in providing medical care to all segments of society, committed to the highest standards of quality and safety",
          ar: "يؤدى هذا القسم دوراً حيوياً فى تقديم الرعاية الطبية لجميع شرائح المجتمع ملتزماً بأعلى معايير الجودة و السلامة",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card8: {
        heading: {
          en: "Outpatient department",
          ar: "  أسنان كبار -أطفال",
        },
        text: {
          en: "This department plays a vital role in providing medical care to all segments of society, committed to the highest standards of quality and safety",
          ar: "يؤدى هذا القسم دوراً حيوياً فى تقديم الرعاية الطبية لجميع شرائح المجتمع ملتزماً بأعلى معايير الجودة و السلامة",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card9: {
        heading: {
          en: "Outpatient department",
          ar: "  خدمات التمريض المنزلى",
        },
        text: {
          en: "This department plays a vital role in providing medical care to all segments of society, committed to the highest standards of quality and safety",
          ar: "يؤدى هذا القسم دوراً حيوياً فى تقديم الرعاية الطبية لجميع شرائح المجتمع ملتزماً بأعلى معايير الجودة و السلامة",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
    },
    testimonialSection: {
      title: {
        en: "Testimonial",
        ar: "شهادة",
      },
      text: {
        en: "See What Are The Patients Saying About us",
        ar: "",
      },
      card1: {
        authortext: {
          en: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Ipsum.",
          ar: "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
        },
        authorSpan: {
          en: "PATIENT",
          ar: "صبور",
        },
        author: {
          en: "John Doe",
          ar: "john Doe",
        },
      },
      card2: {
        authortext: {
          en: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Ipsum.",
          ar: "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
        },
        authorSpan: {
          en: "PATIENT",
          ar: "صبور",
        },
        author: {
          en: "John Doe",
          ar: "john Doe",
        },
      },
      card3: {
        authortext: {
          en: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum Ipsum.",
          ar: "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد.",
        },
        authorSpan: {
          en: "PATIENT",
          ar: "صبور",
        },
        author: {
          en: "John Doe",
          ar: "john Doe",
        },
      },
    },
    latestNewSection: {
      title: {
        en: "Latest News",
        ar: "أحدث الأخبار",
      },
      heading: {
        en: "Our Latest News",
        ar: "آخر أخبارنا",
      },
      card1: {
        author: {
          en: "john Doe",
          ar: "شخص",
        },
        heading: {
          en: "In this hospital there are special surgeon",
          ar: "يوجد في هذا المستشفى جراح خاص",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
        button: {
          en: "Read More",
          ar: "قراءة المزيد",
        },
      },
      card2: {
        author: {
          en: "john Doe",
          ar: "johne Doe",
        },
        heading: {
          en: "In this hospital there are special surgeon",
          ar: "يوجد في هذا المستشفى جراح خاص",
        },
        button: {
          en: "Read More",
          ar: "قراءة المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card3: {
        author: {
          en: "john Doe",
          ar: "johne Doe",
        },
        heading: {
          en: "In this hospital there are special surgeon",
          ar: "يوجد في هذا المستشفى جراح خاص",
        },
        button: {
          en: "Read More",
          ar: "قراءة المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card4: {
        author: {
          en: "john Doe",
          ar: "johne Doe",
        },
        heading: {
          en: "In this hospital there are special surgeon",
          ar: "يوجد في هذا المستشفى جراح خاص",
        },
        button: {
          en: "Read More",
          ar: "قراءة المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
    },
    /* footer */
    footer: {
      text: {
        en: "Al Agouz Specialized Hospital is a multi-specialty hospital offering comprehensive medical facilities, established in 2014",
        ar: "مستشفى العجوز التخصصي هى مستشفى متعددة التخصصات تقدم مرافق طبية شاملة ، تم إنشاؤه في عام 2014",
      },
      title: {
        en: "Al Agouz",
        ar: "العجوز",
      },
      contact: {
        en: "contact us",
        ar: "اتصل بنا",
      },
    },
    quickLinks: {
      name: {
        en: "QuickLinks",
        ar: "روابط سريعة",
      },
      about: {
        en: "about",
        ar: "معلومات عنا",
      },
      services: {
        en: "serviecs",
        ar: "خدمات",
      },
      booking: {
        en: "booking",
        ar: "الحجز",
      },
      faq: {
        en: "faq",
        ar: "التعليمات",
      },
      blogs: {
        en: "blogs",
        ar: "المدونات",
      },
      ourTeam: {
        en: "our Team",
        ar: "فريقنا",
      },
    },
    ourServices: {
      name: {
        en: "Our Servies",
        ar: " خدماتنا",
      },
      dental: {
        en: "dental care",
        ar: "العناية بالأسنان",
      },
      cadric: {
        en: "cardiac clinc",
        ar: "خدمات",
      },
      therapy: {
        en: "massage therapy",
        ar: "العلاج بالتدليك",
      },
      diagnosis: {
        en: "precise diagnosis",
        ar: "التشخيص الدقيق",
      },
      blogs: {
        en: "blogs",
        ar: "المدونات",
      },
      ambulance: {
        en: "ambulance",
        ar: "خدمات الاسعاف",
      },
    },
    subscribe: {
      name: {
        en: "Subscribe",
        ar: "الإشتراك",
      },
      input: {
        en: "Email address",
        ar: "ايميل",
      },
      button: {
        en: "subscribe now",
        ar: "إشترك الآن",
      },
    },
    /* about us */
    featureSection: {
      card1: {
        heading: {
          en: "Years With You",
          ar: "سنوات معك",
        },
        text: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card2: {
        heading: {
          en: "Awards",
          ar: "الجوائز",
        },
        text: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card3: {
        heading: {
          en: "Doctors",
          ar: "الأطباء",
        },
        text: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card4: {
        heading: {
          en: "Satisfied Client",
          ar: "عميل راضٍ",
        },
        text: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
    },
    teamSection: {
      title: {
        en: "Our Doctor",
        ar: "طبيبنا",
      },
      heading: {
        en: "Meet Best Doctors",
        ar: "تعرف على أفضل الأطباء",
      },
      card1: {
        title: {
          en: "Dr. Osama Al-Rifai",
          ar: "دكتور اسامة الرفاعي ",
        },
        text: {
          en: "Consultant general and laparoscopic surgery",
          ar: "استشارى الجراحة العامة وجراحة المناظير",
        },
      },
      card2: {
        title: {
          en: "Dr. Mahmoud Tariq",
          ar: "دكتور محمود طارق",
        },
        text: {
          en: "Consultant of pediatric and newborn surgeries",
          ar: "استشارى جراحات الاطفال وحديثى الولادة",
        },
      },
      card3: {
        title: {
          en: "Dr. Ahmed Lemona",
          ar: "دكتور احمد ليمونة",
        },
        text: {
          en: "PhD and consultant orthopedic surgeon",
          ar: "دكتوراة واستشارى جراحة العظام",
        },
      },
      card4: {
        title: {
          en: "Doctor Ahmed Hatem",
          ar: "دكتور احمد حاتم",
        },
        text: {
          en: "Lecturer of diseases and internal medicine and a consultant rheumatologist",
          ar: "مدرس الامراض والباطنة واستشارى امراض الروماتيزم",
        },
      },
      card5: {
        title: {
          en: "Dr. Mina Esmat",
          ar: "دكتور مينا عصمت",
        },
        text: {
          en: "Consultant Lecturer of Ear, Nose and Throat Surgery",
          ar: "مدرس استشارى جراحة انف واذن وحنجرة",
        },
      },
      card6: {
        title: {
          en: "Dr. Asim Al-Issawy",
          ar: "دكتور عاصم العيساوي",
        },
        text: {
          en: "Pediatrics and neonatology consultant",
          ar: "استشارى طب الاطفال وحديثي الولادة",
        },
      },
      card7: {
        title: {
          en: "Dr. Ahmed Ramadan",
          ar: "د. احمد رمضان",
        },
        text: {
          en: "Consultant and teacher of diseases and endoscopy of the digestive system, liver and infectious diseases",
          ar: "استشارى ومدرس امراض ومناظير الجهاز الهضمي والكبد والامراض المعدية",
        },
      },
      
    },
    /* our team page */
    teamPage: {
      card1: {
        title: {
          en: "Dr. Addition Smith",
          ar: "دكتور إضافة سميث",
        },
        text: {
          en: "Dentist",
          ar: "دكتورالاسنان",
        },
      },
      card2: {
        title: {
          en: "Dr. Mahfuz Riad",
          ar: "دكتور. محفوظ رياض ",
        },
        text: {
          en: "Chiropractor",
          ar: "مقوم العظام",
        },
      },
      card3: {
        title: {
          en: "Dr. David Benjamin",
          ar: "دكتور إضافة سميث",
        },
        text: {
          en: "Cardiologist",
          ar: "طبيب قلب",
        },
      },
      card4: {
        title: {
          en: "Dr. David Benjamin",
          ar: "دكتور إضافة سميث",
        },
        text: {
          en: "Cardiologist",
          ar: "طبيب قلب",
        },
      },
      card5: {
        title: {
          en: "Dr. David Benjamin",
          ar: "دكتور إضافة سميث",
        },
        text: {
          en: "Cardiologist",
          ar: "طبيب قلب",
        },
      },
      card6: {
        title: {
          en: "Dr. David Benjamin",
          ar: "دكتور إضافة سميث",
        },
        text: {
          en: "Cardiologist",
          ar: "طبيب قلب",
        },
      },
    },
    /* faq */
    faqSection: {
      card1: {
        que: {
          en: "How Doctor Can Ease Your Pain?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card2: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card3: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card4: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card5: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card6: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card7: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card8: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card9: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card10: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card11: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card12: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
    },
    /* error page */
    errorPage: {
      heading: {
        en: "Page Not Found",
        ar: "الصفحة غير موجودة",
      },
      link: {
        en: "home",
        ar: "الصفحة الرئيسية",
      },
      title: {
        en: "The Page you were looking for, couldnt be found.",
        ar: "الصفحة التي كنت تبحث عنها ، لا يمكن العثور عليها.",
      },
      text: {
        en: "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.",
        ar: "ربما تمت إزالة الصفحة التي تبحث عنها أو تم تغيير اسمها أو أنها غير متاحة مؤقتًا.",
      },
      button1: {
        en: "back",
        ar: "الرجوع",
      },
      button2: {
        en: "back to home",
        ar: "العودة إلى الصفحة الرئيسية",
      },
    },
    /* login page */
    loginPage: {
      username: {
        en: "username",
        ar: "اسم المستخدم",
      },
      password: {
        en: "password",
        ar: "كلمه السر",
      },
      loginBtn: {
        en: "password",
        ar: "كلمه السر",
      },
      forgotPassword: {
        en: "Forgot Password",
        ar: "هل نسيت كلمة السر",
      },
      text: {
        en: "Dont have any account?",
        ar: "ليس لديك حساب؟",
      },
      registerBtn: {
        en: "Register",
        ar: "يسجل",
      },
    },
    /* register page */
    RegisterPage: {
      name: {
        en: "name",
        ar: "الاسم",
      },
      email: {
        en: "email",
        ar: "الايميل",
      },
      username: {
        en: "username",
        ar: "اسم المستخدم",
      },
      password: {
        en: "password",
        ar: "كلمه السر",
      },
      registerBtn: {
        en: "Register Now",
        ar: "تسجيل الان",
      },
      text: {
        en: "Already have an account?",
        ar: "هل لديك حساب؟",
      },
      loginBtn: {
        en: "Login",
        ar: "تسجيل الدخول",
      },
    },
    /* services page */
    servicesPage: {
      card1: {
        heading: {
          en: "Operations department",
          ar: "قسم العمليات",
        },
        text: {
          en: "3 operating rooms to receive all specialties and operating room equipment according to the highest specifications",
          ar: "عدد 3 غرف عمليات  لاستقبال جميع التخصصات وتجهيزات غرف العمليات تبعا لاعلي المواصفات",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card2: {
        heading: {
          en: "C- Arm",
          ar: "C- Arm",
        },
        text: {
          en: "Phasellus venenatis porta rhoncus. Integer et viverra felis.",
          ar: "علي اعلي مستوي من التجهيزات ويستخدم في الجراحة العامة وعمليات المسالك البولية وجراحة العظام . عمليات الأوعية الدموية ",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card3: {
        heading: {
          en: "Laparoscopic surgery department",
          ar: "قسم جراحة المناظير",
        },
        text: {
          en: "Surgical endoscopy unit A group of university professors of surgery and surgical endoscopy are among the best endoscopic surgeons who have experience in various surgeries using the endoscope",
          ar: "وحدة المناظير الجراحيه مجموعة من اساتذة الجامعه للجراحه والمناظير الجراحيه من افضل جراحي المناظير ممن لديهم خبرة في الجراحات المتنوعه باستخدام المنظار",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card4: {
        heading: {
          en: "Internal department",
          ar: "قسم الداخلى",
        },
        text: {
          en: "All hospital rooms are designed and equipped to the highest standard",
          ar: "جميع غرف المستشفى مصممة ومجهزة على أعلى مستوى",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card5: {
        heading: {
          en: "Outpatient department",
          ar: "قسم العيادات الخارجية",
        },
        text: {
          en: "This department plays a vital role in providing medical care to all segments of society, committed to the highest standards of quality and safety",
          ar: "يؤدى هذا القسم دوراً حيوياً فى تقديم الرعاية الطبية لجميع شرائح المجتمع ملتزماً بأعلى معايير الجودة و السلامة",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
      card6: {
        heading: {
          en: "Emergency department",
          ar: "قسم الطوارئ",
        },
        text: {
          en: "The Emergency Department at Al-Agouz Specialist Hospital is a highly efficient and professional unit, as it provides 24-hour care to patients for all types of medical emergencies. The emergency unit operates 24 hours a day, 7 days a week.",
          ar: "يعتبر قسم الطوارئ في مستشفى العجوز التخصصي وحدة عالية الكفاءة واحترافية، إذ توفر الرعاية للمرضى على مدار ال24 ساعة ولكافة أنواع حالات الطوارئ الطبية. تعمل وحدة الطوارئ على مدار 24 ساعة وطوال أيام الأسبوع، ",
        },
        button: {
          en: "View More",
          ar: "مشاهدة المزيد",
        },
      },
    },
    servicesDetails: {
      heading: {
        en: "Why Medical Had Been So Popular Till",
        ar: "لماذا كان الطب الطبي شائعًا جدًا حتى الآن",
      },
      text: {
        en: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
        ar: "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل عينة كتاب",
      },
      Detail1: {
        en: "Then along come two they",
        ar: "ثم يأتي الاثنان",
      },
      Detail2: {
        en: "That’s just a little bit more than",
        ar: "هذا أكثر من ذلك بقليل",
      },
      Detail3: {
        en: "Standard dummy text ever since",
        ar: "نص وهمي قياسي منذ ذلك الحين",
      },
      Detail4: {
        en: "Simply dummy text of the printing",
        ar: "ببساطة نص وهمي للطباعة",
      },
      Detail5: {
        en: "Make a type specimen book",
        ar: "ببساطة نص وهمي للطباعة",
      },
      progress1: {
        en: "advanced status",
        ar: "حالة متقدمة",
      },
      progress2: {
        en: "6 years Experience",
        ar: "6 سنوات خبرة",
      },
      progress3: {
        en: "Certified Engineers",
        ar: "المهندسين المعتمدين",
      },
      popularQue: {
        en: "Popular Questions",
        ar: "أسئلة شائعة",
      },
      ans: {
        en: "Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
        ar: "النص الوهمي القياسي منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل نموذج كتاب",
      },
      card1: {
        que: {
          en: "How Doctor Can Ease Your Pain?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card2: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card3: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card4: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card5: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
      card6: {
        que: {
          en: "How do I withdraw from a subject?",
          ar: "كيف يمكن للطبيب أن يخفف آلامك؟",
        },
        ans: {
          en: "Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.",
          ar: "وريم إيبسوم( وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر.",
        },
      },
    },
    servicesDetailsRight: {
      tab1: {
        en: "engine diagnoas",
        ar: "تشخيص المحرك",
      },
      tab2: {
        en: "Lube Oil and Filters",
        ar: "زيوت التشحيم والمرشحات",
      },
      tab3: {
        en: "Belts and Hoses",
        ar: "زيوت التشحيم والمرشحات",
      },
      tab4: {
        en: "Air Conditioning",
        ar: "زيوت التشحيم والمرشحات",
      },
      tab5: {
        en: "Brake Repair",
        ar: "زيوت التشحيم والمرشحات",
      },
      tab6: {
        en: "Tire and Wheel Services",
        ar: "زيوت التشحيم والمرشحات",
      },
      downloadHeading: {
        en: "Download",
        ar: "تحميل",
      },
      file1: {
        en: "Download our Brochures",
        ar: "تحميل كتيباتنا",
      },
      file2: {
        en: "Download our Brochures",
        ar: "تحميل كتيباتنا",
      },
    },
    /*blog page */
    blogPage: {
      card1: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card2: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card3: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card4: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card5: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card6: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card7: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card8: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      card9: {
        author: {
          en: "john doe",
          ar: "شحص",
        },
        heading: {
          en: "Dental Care for Women is very important",
          ar: "العناية بالأسنان للنساء مهمة جدا",
        },
        button: {
          en: "Read More",
          ar: "اقراء المزيد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
    },
    blogDetails: {
      author: {
        en: "john doe",
        ar: "شحص",
      },
      text: {
        en: "You just need to enter the keyword and select the keyword type to generate a list of 6 title ideas and suggestions. If you’re not satisfied with the results, you can always hit the refresh button to generate a new list of unique titles.",
        ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريدة.",
      },
      text2: {
        en: "Once you’ve gotten all the titles and have chosen the best one, the next thing you need to do is to craft a magnetic content. Great content marketers excel at creating content.",
        ar: "بمجرد حصولك على جميع العناوين واختيار أفضلها ، فإن الشيء التالي الذي عليك القيام به هو صياغة محتوى مغناطيسي. يتفوق مسوقو المحتوى الكبار في إنشاء المحتوى.",
      },
      text3: {
        en: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        ar: "لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل عينة كتاب.",
      },
      text4: {
        en: "You just need to enter the keyword and select the keyword type to generate a list of 6 title ideas and suggestions. If you’re not satisfied with the results, you can always hit the refresh button to generate a new list of unique titles.",
        ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريدة.",
      },
      text5: {
        en: "You just need to enter the keyword and select the keyword type to generate a list of 6 title ideas and suggestions. If you’re not satisfied with the results, you can always hit the refresh button to generate a new list of unique titles.",
        ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريدة.",
      },
      text6: {
        en: "You just need to enter the keyword and select the keyword type to generate a list of 6 title ideas and suggestions. If you’re not satisfied with the results, you can always hit the refresh button to generate a new list of unique titles.",
        ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريدة.",
      },
      tags: {
        en: "tags",
        ar: "العلامات",
      },
      tagsNames: {
        name1: {
          en: "health",
          ar: "الصحة",
        },
        name2: {
          en: "growth",
          ar: "النمو",
        },
        name3: {
          en: "life",
          ar: "الحياة",
        },
      },
      share: {
        en: "share",
        ar: "مشاركة",
      },
      comments: {
        en: "comments",
        ar: "تعليق",
      },
      mainComment: {
        author: {
          en: "Sonar Z. Moyna",
          ar: "شخص",
        },
        text: {
          en: "Aenean sollicitudin, lorem quis biber idum auctor anisi elit consequat happ quam vel enim augue.",
          ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريد",
        },
      },
      comment1: {
        author: {
          en: "Sonar Z. Moyna",
          ar: "شخص",
        },
        text: {
          en: "Aenean sollicitudin, lorem quis biber idum auctor anisi elit consequat happ quam vel enim augue.",
          ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      comment2: {
        author: {
          en: "Sonar Z. Moyna",
          ar: "شخص",
        },
        text: {
          en: "Aenean sollicitudin, lorem quis biber idum auctor anisi elit consequat happ quam vel enim augue.",
          ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      comment3: {
        author: {
          en: "Sonar Z. Moyna",
          ar: "شخص",
        },
        text: {
          en: "Aenean sollicitudin, lorem quis biber idum auctor anisi elit consequat happ quam vel enim augue.",
          ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      comment4: {
        author: {
          en: "Sonar Z. Moyna",
          ar: "شخص",
        },
        text: {
          en: "Aenean sollicitudin, lorem quis biber idum auctor anisi elit consequat happ quam vel enim augue.",
          ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      comment5: {
        author: {
          en: "Sonar Z. Moyna",
          ar: "شخص",
        },
        text: {
          en: "Aenean sollicitudin, lorem quis biber idum auctor anisi elit consequat happ quam vel enim augue.",
          ar: "تحتاج فقط إلى إدخال الكلمة الرئيسية وتحديد نوع الكلمة الرئيسية لإنشاء قائمة من 6 أفكار واقتراحات للعناوين. إذا لم تكن راضيًا عن النتائج ، فيمكنك دائمًا الضغط على زر التحديث لإنشاء قائمة جديدة بالعناوين الفريد",
        },
        date: {
          en: "21 july 2021",
          ar: " ٢١ يوليو ٢٠٢٢ ",
        },
      },
      replay: {
        name: {
          en: "replay",
          ar: "الرد",
        },
        heading: {
          en: "Leave a Reply",
          ar: "اترك رد",
        },
        author: {
          en: "author",
          ar: "المؤلف",
        },
        email: {
          en: "email",
          ar: "البريد الإلكتروني",
        },
        comment: {
          en: "comment",
          ar: "تعليق",
        },
        button: {
          en: "Submit Comment",
          ar: "إرسال تعليق",
        },
      },
    },
    blogDetailsRight: {
      input: {
        en: "Enter your keywords",
        ar: "أدخل كلمات البحث",
      },
      recentPages: {
        name: {
          en: "recent posts",
          ar: "المشاركات الاخيرة",
        },
        card1: {
          heading: {
            en: "Precious Tips To Help You Get Better.",
            ar: "نصائح ثمينة لمساعدتك على التحسن.",
          },
          date: {
            en: "21 july 2021",
            ar: " ٢١ يوليو ٢٠٢٢ ",
          },
        },
        card2: {
          heading: {
            en: "Ten Doubts You Should Clarify About.",
            ar: "عشر شكوك عليك توضيحها.",
          },
          date: {
            en: "21 july 2021",
            ar: " ٢١ يوليو ٢٠٢٢ ",
          },
        },
        card3: {
          heading: {
            en: "The 10 Steps Needed For Putting.",
            ar: "الخطوات العشر اللازمة للوضع.",
          },
          date: {
            en: "21 july 2021",
            ar: " ٢١ يوليو ٢٠٢٢ ",
          },
        },
      },
      tags: {
        tag1: {
          en: "improvement",
          ar: "تحسين",
        },
        tag2: {
          en: "health",
          ar: "الصحة",
        },
        tag3: {
          en: "life",
          ar: "الحياة",
        },
        tag4: {
          en: "covid",
          ar: "كوفيد",
        },
        tag5: {
          en: "healthy",
          ar: "صحي",
        },
        tag6: {
          en: "growth",
          ar: "النمو",
        },
        tag7: {
          en: "education",
          ar: "تعليم",
        },
        tag8: {
          en: "manage",
          ar: "يدير",
        },
        tag9: {
          en: "general",
          ar: "عام",
        },
      },
      gallery: {
        en: "gallery",
        ar: "الصور",
      },
    },
    /* contact us */
    contact: {
      name: {
        en: "Your name",
        ar: "اسمك",
      },
      email: {
        en: "email",
        ar: "البريد الإلكتروني",
      },
      phone: {
        en: "Phone Numbers",
        ar: "أرقام الهاتف",
      },
      selectDepartment: {
        title: {
          en: "selectDepartment",
          ar: "حدد القسم",
        },
        one: {
          en: "one",
          ar: "واحد",
        },
        two: {
          en: "two",
          ar: "واحد",
        },
        three: {
          en: "three",
          ar: "واحد",
        },
      },
      message: {
        en: "Type Message",
        ar: "اكتب الرسالة",
      },
      button: {
        en: "submit",
        ar: "إرسال",
      },
      text: {
        en: "Contact Us For Any Informations",
        ar: "اتصل بنا للحصول على أي معلومات",
      },
      locationName: {
        en: "Location",
        ar: "الموقع",
      },
      location: {
        en: "The end of Al-Qawmiya Al-Arabiya Street with Imbaba Ring Road",
        ar: "نهاية شارع القومية العربية مع الطريق الدائرى إمبابة",
      },
      address: {
        en: "Location",
        ar: "اتصل بنا للحصول على أي معلومات",
      },
      emailHeading: {
        en: "Email & Phone",
        ar: "اتصل بنا للحصول على أي معلومات",
      },
      emailText: {
        en: "AgouzHospital2000@gmail.com",
        ar: "AgouzHospital2000@gmail.com",
      },
      phoneNumber: {
        en: "01111149544",
        ar: "01111149544",
      },
      follow: {
        en: "Follow Us",
        ar: "تابعنا",
      },
    },
    contactDetails: {
      contactNumber: {
        en: "Contact Number",
        ar: "رقم الاتصال",
      },
      email: {
        en: "Email Address",
        ar: "عنوان البريد الإلكتروني",
      },
      address: {
        heading: {
          en: "Email Address",
          ar: "عنوان ",
        },
        text: {
          en: "The end of Al-Qawmiya Al-Arabiya Street with Imbaba Ring Road",
          ar: "نهاية شارع القومية العربية مع الطريق الدائرى إمبابة",
        },
      },
    },
    pages: {
      home: {
        en: "Home",
        ar: "الصفحة الرئيسية",
      },
      pages: {
        en: "Pages",
        ar: "الصفحات",
      },
      services: {
        en: "Services",
        ar: "خدمات",
      },
      blog: {
        en: "blog",
        ar: "مقالات",
      },
      contact: {
        en: "Contact us",
        ar: "اتصل بنا",
      },
      servicesDetails: {
        en: "services Details",
        ar: "تفاصيل الخدمات",
      },
      ourTeam: {
        en: "Our Team",
        ar: "فريقنا",
      },
      blogDetails: {
        en: "blog Details",
        ar: "تفاصيل المقالات",
      },
      about: {
        en: "about us",
        ar: "معلومات عنا",
      },
      faq: {
        en: "faq",
        ar: "التعليمات",
      },
      booking: {
        en: "booking",
        ar: "الحجز",
      },
      latest: {
        en: "news",
        ar: "اخبار",
      },
      error: {
        en: "error404",
        ar: "خطأ",
      },
      login: {
        en: "login",
        ar: "تسجيل الدخول",
      },
      register: {
        en: "register",
        ar: "تسجيل",
      },
      title: {
        en: "El Agouez Specialist Hospital",
        ar: "مستشفي العجوز التخصصي",
      }
    },
    contactHeading: {
      heading: {
        en:"Contact Us",
        ar:"تواصل معنا"
      },
      text: {
        en:"Contact Us",
        ar:"اتصل بنا"
      },
    }
  },
};

export const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    toggleLang: (state, action) => {
      if (action.payload) {
        state.value.lang = action.payload;
        localStorage.setItem("lang", JSON.stringify(action.payload));
      } else {
        switch (state.value.lang) {
          case "ar":
            state.value.lang = "ar";
            break;
          case "en":
            state.value.lang = "ar";
            break;
          default:
            state.value.lang = "ar";
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleLang } = langSlice.actions;

export default langSlice.reducer;
