import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, HashRouter } from "react-router-dom";

// Elements
import BackToTop from "./elements/back-top";

// All Pages Router
import Index from "./pages/index";
import AboutUs from "./pages/aboutus";
import Team from "./pages/team";
import Services from "./pages/services";
import FormLogin from "./pages/formLogin";
import FormRegister from "./pages/formRegister";
import FormForgetPassword from "./pages/formForgetPassword";
import Faq from "./pages/faq";
import ContactUs from "./pages/contactUs";
import FamilyCart from "./pages/FamilyCart";
import Booking from "./pages/booking";
import BlogGrid from "./pages/blogGrid";
import BlogDetails from "./pages/blogDetails";
import Error from "./pages/error404";

import { useSelector } from "react-redux";
import HeroSocail from "./elements/heroSocail";


// toastify Container
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import See from "./pages/see";
import Goal from "./pages/goal";
import Message from "./pages/message";
import ServicesDetailsEmergency from "./pages/servicesDetailsEmergency";
import ServicesDetailsOutDoctor from "./pages/servicesDetailsOutDoctor";
import ServicesDetailsSurgery from "./pages/servicesDetailsSurgery";
import ServicesDetailsWoman from "./pages/servicesDetailsWoman";
import ServicesDetailsBaby from "./pages/servicesDetailsBaby";
import ServicesDetailsBoon from "./pages/servicesDetailsBoon";
import ServicesDetailsMasalk from "./pages/servicesDetailsMasalk";
import ServicesDetailsPhysicalTherapy from "./pages/ServicesDetailsPhysicalTherapy";
import ServicesDetailsDiagnosticX_rays from "./pages/ServicesDetailsDiagnosticX-rays";
import ServicesDetailsLab from "./pages/ServicesDetailsLab";

const Markup = () => {
  const lang = "ar";
  const main = useSelector((state) => state.lang.value.pages);

  function ChangeTitle() {
    useEffect(() => {
      document.title = main.title['ar'];
    }, [lang]);
  }
  ChangeTitle();

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/team" exact component={Team} />
          <Route path="/services" exact component={Services} />
          <Route path="/see" exact component={See} />
          <Route path="/goal" exact component={Goal} />
          <Route path="/message" exact component={Message} />
          <Route path="/service-detail-emergency" exact component={ServicesDetailsEmergency} />
          <Route path="/service-detail-surgery" exact component={ServicesDetailsSurgery} />
          <Route path="/service-detail-woman" exact component={ServicesDetailsWoman} />
          <Route path="/service-detail-baby" exact component={ServicesDetailsBaby} />
          <Route path="/service-detail-boon" exact component={ServicesDetailsBoon} />
          <Route path="/service-detail-urinary-tract" exact component={ServicesDetailsMasalk} />
          <Route path="/service-detail-outpatient-clinic" exact component={ServicesDetailsOutDoctor} />
          <Route path="/service-detail-physical-therapy" exact component={ServicesDetailsPhysicalTherapy} />
          <Route path="/service-detail-diagnostic-x-ray" exact component={ServicesDetailsDiagnosticX_rays} />
          <Route path="/service-detail-lab" exact component={ServicesDetailsLab} />

          <Route path="/form-login" exact component={FormLogin} />
          <Route path="/form-register" exact component={FormRegister} />
          <Route
            path="/form-forget-password"
            exact
            component={FormForgetPassword}
          />
          <Route path="/faq" exact component={Faq} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/family-cart" exact component={FamilyCart} />
          <Route path="/booking" exact component={Booking} />
          <Route path="/blog-grid" exact component={BlogGrid} />
          <Route path="/blog-details" exact component={BlogDetails} />
          <Route component={Error} />
        </Switch>

        <ToastContainer position="bottom-left"/>
        <HeroSocail />
      </BrowserRouter>

      <BackToTop />
    </>
  );
};

export default Markup;
