import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import servicesPic1 from "../../images/Physiotherapy-Rooms.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";

const ServicesDetailsLab = () => {

  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.servicesDetails);
  const mainRight = useSelector((state) => state.lang.value.servicesDetailsRight);
  const { servicesDetails } = useSelector((state) => state.lang.value.pages)

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <HeroPage text={servicesDetails[lang]} />

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-30">
                <div className="ttr-media mb-30 ">
                  <img src={servicesPic1} className="rounded w-full" alt="" />
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h2 className="title mb-15">
                      المعمل
                    </h2>
                    <p className="">
                        ‫معامل ‬‫المستشفى‬ ‫تقوم‬ ‫بأجراء‬ ‫كافة‬ ‫التحاليل‬ ‫الطبية‬ ‫بواسطة‬ ‫احدث‬ ‫اﻷجهزة‬ ‫وتحت‬
‫اشراف ‬‫نخبة‬ ‫من‬ ‫الفنين‬
                     </p>
                  
                  </div>
                 
                </div>
                
              </div>
              <div className="col-lg-4">
                <aside className="sticky-top pb-1">
                  <div className="widget">
                    <ul className="service-menu">
                      <li className="">
                        <Link to="/service-detail-emergency">
                          <span>خدمات الطوارئ</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li >
                        <Link to="/service-detail-surgery">
                          <span>الجراحة وغرف العمليات</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li >
                        <Link to="/service-detail-outpatient-clinic">
                          <span>العيادات الخارجية</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li >
                        <Link to="/service-detail-woman">
                          <span>النساء والولادة وعلاج العقم وتأخر الانجاب</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-baby">
                          <span>طب الاطفال وحديثى الولادة</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-boon">
                          <span>جراحة العظام</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-urinary-tract">
                          <span>المسالك البولية </span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li >
                        <Link to="/service-detail-physical-therapy">
                          <span>العلاج الطبيعى</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li >
                        <Link to="/service-detail-diagnostic-x-ray">
                          <span>اﻷشعة التشخيصية</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li className="active">
                        <Link to="/service-detail-lab">
                          <span>المعمل</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="widget">
                    <div className="brochure-bx">
                      <h5 className="title-head">{mainRight.downloadHeading[lang]}</h5>
                      <Link to="#" className="download-link">
                        <img src={pdf} alt="" />
                        <h5 className="title">{mainRight.file1[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                      <Link to="#" className="download-link">
                        <img src={doc} alt="" />
                        <h5 className="title">{mainRight.file2[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                    </div>
                  </div> */}
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default ServicesDetailsLab;
