import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const WidgetTag = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const {tags} = useSelector((state) => state.lang.value.blogDetailsRight);
  return (
    <>
      <div className="widget widget_tag_cloud">
        <h4 className="widget-title">Tags</h4>
        <div className="tagcloud">
          <Link to="#">{tags.tag1[lang]}</Link>
          <Link to="#">{tags.tag2[lang]}</Link>
          <Link to="#">{tags.tag3[lang]}</Link>
          <Link to="#">{tags.tag4[lang]}</Link>
          <Link to="#">{tags.tag5[lang]}</Link>
          <Link to="#">{tags.tag6[lang]}</Link>
          <Link to="#">{tags.tag7[lang]}</Link>
          <Link to="#">{tags.tag8[lang]}</Link>
          <Link to="#">{tags.tag9[lang]}</Link>
        </div>
      </div>
    </>
  );
};

export default WidgetTag;
