import React from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import FeatureSection3 from "../elements/featureSection3";
import TeamSection from "../elements/team";
import LatestNewsSection from "../elements/latestNewsSlider";

import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";
const Services = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const main = useSelector((state) => state.lang.value.servicesSliderSection);
  const { services } = useSelector((state) => state.lang.value.pages)
  return (
    <>
      <Header services="text-secondary" />

      <div className="page-content bg-white">

        <HeroPage text={services[lang]} />

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                  <div>
                    <img
                      src={require("../../images/emergancy.jpg")}
                      className="rounded w-full mb-3"
                      alt=""
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}

                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">خدمات الطوارئ</h3>
                    <p>
                      {`يقدم قسم الطوارئ في مستشفى العجوز التخصصى خدمة اسعاف المصابين 24ساعة/7أيام. ونقدم مجموعة كاملة من الخدمات الطبية الطارئة ﻷكثر من 500 مريض كل شهر.بالتعاون مع اﻷقسام المتخصصة و العيادات الخارجية كى نضمن المزيد من العلاج الفعال في جميع اﻷوقات .`.substring(0, 100)}...
                    </p>
                    <Link
                      to="/service-detail-emergency"
                      className="btn btn-primary light"
                    >
                      {main.card1.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature2">
                  <div>
                    <img
                      src={require("../../images/emergancy.jpg")}
                      className="rounded w-full mb-3"
                      alt=""
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}

                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">العيادات الخارجية</h3>
                    <p>
                      {'تعمل العيادات الخارجية بمستشفى العجوز التخصصى من الساعة 12 ظهرا إلى 1 ص وفي جميع التخصصات الطبية ويقوم باﻷشراف عليها مجموعة من الاستشاريين واﻷخصائيين ذو الكفاءة والخبرة الطويلة.'.substring(0, 100)}...                    </p>
                    <Link
                      to="/service-detail-outpatient-clinic"
                      className="btn btn-primary light"
                    >
                      {main.card2.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature3">
                  <div>
                    <img
                      src={require("../../images/Surgery.jpg")}
                      className="rounded w-full mb-3 "
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">الجراحه وغرف العمليات</h3>
                    <p>
                      {`يلتزم قسم الجراحة بتقديم مجموعة واسعة من الخدمات عالية الجودة للمرضى واﻷطباء الذين يقومون بإحالتهم للقسم وتوفير مختلف أقسام مستشفى العجوز وأقسامها المتخصصة التى تعمل بالتزامن مع قسم الجراحه العامة كل اﻹجراءات التشخيصية والجراحية باستخدام أحدث الأدوات والتقنيات`.substring(0, 100)}...
                    </p>
                    <Link
                      to="/service-detail-surgery"
                      className="btn btn-primary light"
                    >
                      {main.card3.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature2">
                  <div>
                    <img
                      src={require("../../images/Surgery.jpg")}
                      className="rounded w-full mb-3 "
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">نسا وتوليد وعلاج عقم</h3>
                    <p>
                      {`يهدف قسم أمراض النساء والولادة وعلاج العقم وتأخر الإنجاب إلى توفير خدمات عالية الجودة وملائمة لجميع الازواج والسيدات من خلال التشخيص الطبى المتميز كما نعمل على توفير خدمات الرعاية المتميزة لحالات ما قبل الولادة وما بعد الولادة وأمراض النساء`.substring(0, 100)}...
                    </p>
                    <Link
                      to="/service-detail-woman"
                      className="btn btn-primary light"
                    >
                      {main.card4.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature3">
                <div>
                    <img
                      src={require("../../images/carePape.jpg")}
                      className="rounded w-full mb-3 "
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title"> الاطفال وحديثى الولادة</h3>
                    <p>
{`                      توفر وحدة العناية المركزة لحديثى الولادة في مستشفى العجوز الرعاية الحرجة وغير الحرجة للمواليد الجدد الذين يعانون من أمراض ومشاكل طبية   
`.substring(0, 100)}...                    </p>
                    <Link
                      to="/service-detail-baby"
                      className="btn btn-primary light"
                    >
                      {main.card5.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                <div>
                    <img
                      src={require("../../images/Physiotherapy-Rooms.jpg")}
                      className="rounded w-full mb-3 "
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">جراحة العظام</h3>
                    <p>
               {`     ‫تتميز‬‫المستشفى‬ ‫بفريق‬ ‫طبي‬ ‫ذو‬ ‫خبرة‬ ‫عالية‬ ‫للتعامل‬ ‫مع‬ ‫مشاكل‬ ‫العظام‬ ‫و‬ ‫المفاصل‬ ‫و‬ ‫ما‬ ‫يتبع‬ ‫ذلك‬ ‫من‬ ‫إعادة‬ ‫التأهيل‪.‬‬ ‫و‬ ‫تتعدد‬
‫الخدمات ‬العلاجية‬ ‫المقدمة‬ ‫من‬ ‫القسم‬      
   `.substring(0, 100)}...           </p>
                    <Link
                      to="/service-detail-boon"
                      className="btn btn-primary light"
                    >
                      {main.card6.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                <div>
                    <img
                      src={require("../../images/carePape.jpg")}
                      className="rounded w-full mb-3 "
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">مسالك البولية</h3>
                    <p>
                    {` ‫يعمل ‬‫بقسم‬ ا‫لمسالك‬ ‫البولية‬ ‫مجموعة‬ ‫استشاريين‬ ‫من‬ ‫نخبة‬ ‫اﻷطباء‬ ‫و‬ ‫يستقبل‬ ‫المرضى‬ ‫يوميا‬ ‫و‬ ‫يتوفر‬ ‫بالقسم‬ ‫العمليات‬ ‫و‬
                                            ‫الجراحات ‬‫و‬ ‫المناظير‬ ‫الحديثة‬ ‫لجراحات‬ ‫الكلى‬ ‫والمسالك‬ ‫البولية‬ ‫‪.‬‬ ‫و‬ ‫ايضا‬ ‫ًًَ‬ ‫قسم‬ ‫التفتيت‬ ‫لتفتيت‬ ‫الحصوات‬ ‫المختلفة.‬ ‫‪‬‬    <br />
                                            ‫مميزات ‬‫قسم‬ ‫المسالك‬ ‫البولية‬ ‫ب‬‫مستشفى‬‫ العجوز‬ ‫التخصصى‬ ‫‪:‬‬
                                        `.substring(0, 100)}...
                    </p>
                    <Link
                      to="/service-detail-urinary-tract"
                      className="btn btn-primary light"
                    >
                      {main.card7.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                <div>
                    <img
                      src={require("../../images/Physiotherapy-Rooms.jpg")}
                      className="rounded w-full mb-3 "
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">العلاج الطبيعى</h3>
                    <p>
{`                    يعد قسم العلاج الطبيعى فى مستشفى العجوز التخصصى من أكبر المراكز فى شمال الجيزة القسم مجهز  تجهيزا عاليا بأحدث اﻷجهزة فى علاج جميع أنواع الأمراض المختلفة.                  
`.substring(0, 100)}...                    </p>
                    <Link
                      to="/service-detail-physical-therapy"
                      className="btn btn-primary light"
                    >
                      {main.card8.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                <div>
                    <img
                      src={require("../../images/Physiotherapy-Rooms.jpg")}
                      className="rounded w-full mb-3 "
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">اﻷشعة التشخيصية</h3>
                    <p>
{`                    يؤدى قسم الاشعة التشخيصية دورا هاما في إدارة حالات المرضى من خلال تقديم أحدث خدمات التصوير وأكثرها تقدما من الناحية التكنولوجية في كافة التخصصات الطبية. فهو يوفر خدمات التصوير التشخيصى باﻷشعة وبعض الخدمات العلاجية لتمكين الكوادر الطبية من أداء عملها بفاعلية ومساعدة المرضى.<br/>يضم القسم فريقا ذا كفاءة عالية من اﻷخصائيين والاستشاريين في مجال التصوير باﻷشعة المعتمدين فضلا عن التقنيين وموظفى الخدمات المساعدة الذين يبذلون كل ما بوسعهم لتوفير خدمات التصوير الطبى وفق  أرقى معايير الجودة لجميع المرضى وهؤلاء الموظفين مدربين على استخدام مجموعة واسعة من وسائل وتقنيات التصوير باﻷشعة.ويوفر فريقنا المؤلف من أطباء متخصصين خدمات التصوير التشخيصى والتداخلى التى تجمع بين خدمات تصوير الجسم ومنطقة الحوض إلى تصوير اﻷعصاب واﻷطفال والقلب واﻷوعية الدموية والجهاز العضلى الهيكلى والثدى واﻷورام وأمراض النساء فضلا عن خدمات التصوير التداخلى والتصوير لحالات الحوادث والطوارئ.                    
`.substring(0, 100)}...                    
                    </p>
                    <Link
                      to="/service-detail-diagnostic-x-ray"
                      className="btn btn-primary light"
                    >
                      {main.card9.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx2 feature1">
                <div>
                    <img
                      src={require("../../images/Physiotherapy-Rooms.jpg")}
                      className="rounded w-full mb-3 "
                      style={{ height: "170px", width: "100%", objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">المعمل</h3>
                    <p>
                  {`  ‫معامل ‬‫المستشفى‬ ‫تقوم‬ ‫بأجراء‬ ‫كافة‬ ‫التحاليل‬ ‫الطبية‬ ‫بواسطة‬ ‫احدث‬ ‫اﻷجهزة‬ ‫وتحت‬
                    ‫اشراف ‬‫نخبة‬ ‫من‬ ‫الفنين‬                    
                   `.substring(0, 100)}... </p>
                    <Link
                      to="/service-detail-lab"
                      className="btn btn-primary light"
                    >
                      {main.card9.button[lang]}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <FeatureSection3 /> */}

        {/* <TeamSection /> */}

        {/* <LatestNewsSection /> */}
      </div>

      <Footer />
    </>
  );
};

export default Services;
