import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import servicesPic1 from "../../images/carePape.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";

const ServicesDetailsBaby = () => {

    const lang = 'ar';
    const main = useSelector((state) => state.lang.value.servicesDetails);
    const mainRight = useSelector((state) => state.lang.value.servicesDetailsRight);
    const { servicesDetails } = useSelector((state) => state.lang.value.pages)

    return (
        <>
            <Header />

            <div className="page-content bg-white">
                <HeroPage text={servicesDetails[lang]} />

                <section className="section-area section-sp1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-30">
                                <div className="ttr-media mb-30 ">
                                    <img src={servicesPic1} className="rounded w-full" alt="" />
                                </div>
                                <div className="clearfix">
                                    <div className="head-text mb-30">
                                        <h2 className="title mb-15">
                                            قسم الأطفال وحديثى الولادة
                                        </h2>
                                        <p className="">
                                            تتعاون الوحدة على نحو مباشر مع قسم أمراض النساء والولادة وقسم الجراحة والطوارئ وغرف العمليات ووحدة رعاية ما بعد الولادة والرعاية المنزلية وينصب تركيزنا الرئيسى على توفير أفضل رعايه ممكنة وخلق بيئة امنة للمواليد الجدد وعائلاتهم كي يحيا هؤلاء الأطفال حياة سعيدة.                    </p>
                                        <p>‫وتتخصص‬ ‫وحدة‬ ‫الرعاية‬ ‫المركزة‬ ‫لحديثي‬ ‫الولادة‬ ‫في‬ ‫علاج‬ ‫المواليد‬ ‫الجدد‬ ‫منذ‬ ‫الولادة‬ ‫وحتى‬ ‫عمر‬ ‫ثلاثة‬ ‫أشهر‪،‬كما‬ ‫تتميز‬
                                            ‫مستشفى‬ ‫العجوز‬ ‫بوحدات‬ ‫حضانات‬ ‫متطورة‬ ‫حيث‬ ‫يمكن‬ ‫تقديم‬ ‫ا‬ ‫لرعاية‬ ‫بسرعة‬ للأطفال ‫الذين‬ ‫يواجهون‬ ‫مشكلات‬ ‫بعد‬ ‫الولادة‪.‬‬
                                            ‫ويتألف‬ ‫القسم‬ ‫من‬ ‫عدة‬ ‫استشاريين‬ ‫و‬ ‫اخصائيين‬ ‫لتقديم‬ ‫خدمات‬ ‫الرعاية‬ ‫لكل‬ ‫الأطفال‬ ‫حديثي‬ ‫الولادة‪.‬‬ ‫و‬ ‫تتضمن‬ ‫مرافق‬ ‫الوحدة‬ ‫أيضًا‬
                                            ‫غرفة‬ ‫عزل‬ ‫للمواليد‬ ‫الجدد‬ ‫الذين‬ ‫يعانون‬ ‫من‬ ‫خطورة‬ ‫محتملة‬ ‫في‬ ‫التقاط‬ ‫العدوى‪.‬‬
                                            ‫ونهدف‬ ‫إلى‬ ‫توفي‬‫ر‬ ‫أعلى‬ ‫معايير‬ ‫الرعاية‬ ‫والسلامة‬ ‫وفقًا‬ ‫للوائح‬ ‫والمعايير‬ ‫الصارمة‬ ‫لوزارة‬ ‫الصحة‬ ‫المصرية‬ ‫و‬ ‫منظمة‬ ‫الصحة‬
                                            ‫العالمية‬ ‫ومن‬ ‫أجل‬ ‫تحقيق‬ ‫هدفنا‬ ‫قمنا‬ ‫بتطبيق‬ ‫نظام‬ ‫ضمان‬ ‫جودة‬ ‫شامل‪،‬‬ ‫يشتمل‬ ‫على‬ ‫قوائم‬ ‫واستبيانات‬ ‫لقياس‬ ‫رضا‬ ‫العملاء‬ ‫يكملها‬
                                            ‫الوالدان‪،‬ ‬ فضلا ‫عن‬ ‫نظام‬ ‫لتقييم‬ ‫حالة‬ ‫المولود‬ ‫عند‬ ‫الترخيص‬ ‫له‬ ‫بالمغادرة‪،‬‬ ‫وبرامج‬ ‫تثقيف‬ ‫الأسرة‬ ‫من‬ ‫أجل‬ ‫فترة‬ ‫ما‬ ‫بعد‬ ‫العلاج‬ ‫في‬
                                            ‫المستشفى‪.‬ ‬ ‫كما‬ ‫نتعاون‬ ‫مع‬ ‫خدمات‬ ‫الرعاية‬ ‫المنزلية‬ ‫والخدمات‬ ‫اﻹجتماعية‬ ‫في‬ ‫فترة‬ ‫المتابعة‬ ‫عندما‬ ‫تقتضي‬ ‫الحاجة‬ ‫ذلك‪.‬ ‬<br/>
                                       
                                    
                                       ‫ويعمل ‬‫قسم‬ ‫طب‬ ‫الاطفال‬ ‫بمستشفى‬ ‫العجوز‬ ‫التخصصى‬ ‫بالتعاون‬ ‫مع‬ ‫اﻷقسام‬ ‫اﻷخرى‬ ‫في‬ ‫المستشفى‪،‬‬ لا‬ ‫سيما‬ ‫قسم‬ ‫اﻷنف‬ ‫واﻷذن‬
‫والحنجرة ل‬‫لأطفال‪،‬‬ ‫وقسم‬ ‫طب‬ ‫العظام‬ ‫ل‬‫لأطفال‪،‬‬ ‫وقسم‬ ‫أمراض‬ ‫النساء‬ ‫والولادة‪،‬‬ ‫وقسم‬ ‫المختبرات‬ ‫الطبية‬ ‫من‬ ‫أجل‬ ‫ضمان‬ ‫حصول‬
‫مرضانا ‬‫على‬ ‫أعلى‬ ‫معايير‬ ‫الرعاية‬ ‫الممكنة‬ ‫وأكثرها‬ ‫ملائمة‪.‬‬
                                       </p>
                                       <p className="fw-bold">
                                       ‫وينقسم‬‫ القسم‬ ‫إلى‬ ‫الوحدات‬ ‫الفرعية‬ ‫التالية‪:‬‬
                                       </p>

                                       <ul>
                                        <li>‫وحدة ‬‫العيادات‬ ‫الخارجية‬ ‫للألطفال‬</li>
                                        <li>‫وحدة ‬‫المرضى‬ ‫الداخليين‬ ‫من‬ ‫اﻷطفال‬</li>
                                        <li>‫وحدة‬‫ الطوارئ‬</li>
                                        <li>‫وحدة ‬‫اﻷطفال‬ ‫حديثي‬ ‫الولادة‬</li>
                                        <li>‫وحدة ‬‫عناية‬ ‫مركزة‬ ‫للأطفال‬ ‫من‬ ‫سن‬ ‫‪6‬‬ ‫شهور‬ ‫ل‬ ‫‪13‬‬ ‫سنة‬</li>
                                       </ul>

                                        <p className="fw-bold">‫توفر ‬‫وحدة‬ ‫العناية‬ ‫المركزة‬ ‫لحديثي‬ ‫الولادة‬ ‫الخدمات‬ ‫التالية‪:‬‬</p>
                                        <p className="fw-bold">‫عيادة ‬‫الأطفال‬ ‫الرضّع‬</p>
                                        <p>
                                        ‫هي ‬‫عيادة‬ ‫متخصصة‬ ‫في‬ ‫رعاية‬ ‫الأطفال‬ ‫الخدّج‬ ‫الذين‬ ‫تصل‬ ‫فترة‬ ‫حملهم‬ ‫لثالثين‬ ‫أسبو‬عا ‫أو‬ ‫أقل‪،‬‬ ‫ونحن‬ ‫مؤهلون‬ ‫لتحديد‬ ‫حالات‬
‫القلب‬‫ والدورة‬ ‫الدموية‬ ‫غير‬ ‫المستقرة‬ ‫والتعامل‬ ‫معها‪،‬‬ ‫فضلً‬ا ‫عن‬ ‫مشاكل‬ ‫الجهاز‬ ‫الهضمي‪،‬‬ ‫ورعاية‬ ‫المواليد‬ ‫الذين‬ ‫خضعوا‬
‫لعمليات ‬‫جراحية‪.‬‬ ‫كما‬ ‫تضم‬ ‫العيادة‬ ‫مجموعة‬ ‫من‬ ‫الكوادر‬ ‫التي‬ ‫تتمتع‬ ‫بخبرات‬ ‫مكثفة‬ ‫في‬ ‫رعاية‬ ‫الأطفال‬ ‫الذين‬ ‫يعانون‬ ‫من‬
‫التشوهات ‬‫الجسدية‬ ‫التي‬ ‫تعود‬ ‫إلى‬ ‫أسباب‬ ‫وراثية‬ ‫في‬ ‫العائلة‬ ‫(تشوهات‬ ‫وراثية)‬ ‫أو‬ ‫التي‬ ‫تحدث‬ ‫أثناء‬ ‫الولادة‬ ‫(التشوهات‬ ‫الخلقية)‪.‬‬
                                        </p>
                                        <p className="fw-bold">‫الأنف ‬‫والأذن‬ ‫والحنجرة‬</p>
                                        <p>
                                        ‫علاج ‬‫الحالات‬ ‫المرتبطة‬ ‫بالأذن‬ ‫واﻷنف‬ ‫والحنجرة‬ ‫مثل‬ ‫التهابات‬ ‫اﻷذن‪،‬‬ ‫ومشاكل‬ ‫الجيوب‬ ‫اﻷنفية‬ ‫أو‬ ‫مشاكل‬ ‫التنفس‪،‬‬ ‫وقد‬ ‫تشتمل‬
‫بعض ‬‫العلاجات‬ ‫الخاصة‬ ‫بهذه‬ ‫الحالات‬ ‫على‬ ‫أنابيب‬ ‫اﻷذن‬ ‫أو‬ ‫جراحة‬ ‫اللوزتين‪.‬‬
                                        </p>

                                        <p className="fw-bold">‫العلاج‬‫ الطبيعي‬</p>
                                        <p>‫تمارين‬‫ خاصة‬ ‫لعالج‬ ‫مشاكل‬ ‫الرئة‬ ‫والتنفس‬ ‫لدى‬ ‫المواليد‪،‬‬ ‫مثل‬ ‫تلك‬ ‫التي‬ ‫قد‬ ‫تظهر‬ ‫لدى‬ ‫اﻷطفال‬ ‫الخدّج‪.‬‬</p>
                                        <p className="fw-bold">‫العلاج ‬‫الوظ‬‫ائفي‬</p>
                                        <p>‫توفير‬‫خدمات‬ ‫التقييم‪،‬‬ ‫والتحفيز‬ ‫الحسي‬ ‫وتمارين‬ ‫الحركة‬ ‫من‬ ‫قبل‬ ‫معالج‬ ‫متخصص‪،‬‬ ‫ويوفر‬ ‫هذا‬ ‫العلاج‬ ‫لحالات‬ ‫خاصة‬ ‫مثل‬
‫اﻷطفال ‬‫الذين‬ ‫يولدون‬ ‫مبكر‬‫ا ‬‫ويعانون‬ ‫من‬ ‫مشاكل‬ ‫في‬ ‫التغذية‪.‬‬
</p>
                                    </div> 

                                </div>

                            </div>
                            <div className="col-lg-4">
                                <aside className="sticky-top pb-1">
                                    <div className="widget">
                                        <ul className="service-menu">
                                            <li className="">
                                                <Link to="/service-detail-emergency">
                                                    <span>خدمات الطوارئ</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-surgery">
                                                    <span>الجراحة وغرف العمليات</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-outpatient-clinic">
                                                    <span>العيادات الخارجية</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-woman">
                                                    <span>النساء والولادة وعلاج العقم وتأخر الانجاب</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <Link to="/service-detail-baby">
                                                    <span>طب الاطفال وحديثى الولادة</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-boon">
                                                    <span>جراحة العظام</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-urinary-tract">
                                                    <span>المسالك البولية </span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-physical-therapy">
                                                    <span>العلاج الطبيعى</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-diagnostic-x-ray">
                                                    <span>اﻷشعة التشخيصية</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-lab">
                                                    <span>المعمل</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="widget">
                    <div className="brochure-bx">
                      <h5 className="title-head">{mainRight.downloadHeading[lang]}</h5>
                      <Link to="#" className="download-link">
                        <img src={pdf} alt="" />
                        <h5 className="title">{mainRight.file1[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                      <Link to="#" className="download-link">
                        <img src={doc} alt="" />
                        <h5 className="title">{mainRight.file2[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                    </div>
                  </div> */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </>
    );
};

export default ServicesDetailsBaby;
