import React from "react";

// Import Images
import teamMember1 from "../../images/team/1.png";
import teamMember2 from "../../images/team/2.png";
import teamMember3 from "../../images/team/3.png";
import teamMember4 from "../../images/team/4.png";
import teamMember5 from "../../images/team/5.png";
import teamMember6 from "../../images/team/6.png";
import teamMember7 from "../../images/team/7.png";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/square-dots-orange.png";
import ptImg3 from "../../images/shap/line-circle-blue.png";
import ptImg4 from "../../images/shap/wave-blue.png";
import ptImg5 from "../../images/shap/circle-dots.png";
import { useSelector } from 'react-redux';


const Team = () => {
	const lang = useSelector((state) => state.lang.value.lang);
	const main = useSelector((state) => state.lang.value.teamSection);
  return (
    <>
      <section className="section-area section-sp3 team-wraper" id='team'>
        <div className="container">
          <div className="heading-bx text-center">
            <h6 className="title-ext text-secondary">{main.title[lang]}</h6>
            <h2 className="title">{main.heading[lang]}</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-member">
                <div className="team-media">
                  <img src={teamMember1} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">{main.card1.title[lang]}</h4>
                    <span className="text-secondary">{main.card1.text[lang]}</span>
                  </div>
                  <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-member active">
                <div className="team-media">
                  <img src={teamMember2} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">{main.card2.title[lang]}</h4>
                    <span className="text-secondary">{main.card2.text[lang]}</span>
                  </div>
                  <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-member">
                <div className="team-media">
                  <img src={teamMember3} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">{main.card3.title[lang]}</h4>
                    <span className="text-secondary">{main.card3.text[lang]}</span>
                  </div>
                  <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-member">
                <div className="team-media">
                  <img src={teamMember4} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">{main.card4.title[lang]}</h4>
                    <span className="text-secondary">{main.card4.text[lang]}</span>
                  </div>
                  <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-member">
                <div className="team-media">
                  <img src={teamMember5} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">{main.card5.title[lang]}</h4>
                    <span className="text-secondary">{main.card5.text[lang]}</span>
                  </div>
                  <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-member">
                <div className="team-media">
                  <img src={teamMember6} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">{main.card6.title[lang]}</h4>
                    <span className="text-secondary">{main.card6.text[lang]}</span>
                  </div>
                  <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="team-member">
                <div className="team-media">
                  <img src={teamMember7} alt="" />
                </div>
                <div className="team-info">
                  <div className="team-info-comntent">
                    <h4 className="title">{main.card7.title[lang]}</h4>
                    <span className="text-secondary">{main.card7.text[lang]}</span>
                  </div>
                  <ul className="social-media">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="pt-img1 animate1" src={ptImg1} alt="" />
        <img className="pt-img2 animate2" src={ptImg2} alt="" />
        <img className="pt-img3 animate-rotate" src={ptImg3} alt="" />
        <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
        <img className="pt-img5 animate-wave" src={ptImg5} alt="" />
      </section>
    </>
  );
};

export default Team;
