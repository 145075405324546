import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  bookAppointThunk,
  getOpenThunk,
  getProvidersThunk,
  getServicesThunk,
} from "./bookThunk";
import { toast } from "react-toastify";

const initialState = {
  services: [],
  provider: [],
  open: [],
  errorsMsg: { open: "" },
  loading: false,
  loadingProvider: false,
  loadingOpen: false,
};

// get services
export const getServices = createAsyncThunk(
  "book/getServices",
  getServicesThunk
);

// get all doctors
export const getProviders = createAsyncThunk(
  "book/getProviders",
  getProvidersThunk
);

// get open book for doctors
export const getOpen = createAsyncThunk("book/getOpen", getOpenThunk);

// post appoineMent
export const bookAppoint = createAsyncThunk(
  "book/bookAppoint",
  bookAppointThunk
);

export const bookSlice = createSlice({
  name: "book",
  initialState,
  reducers: {
    clearValue: (state, action) => {
      state.open = []
    }
  },
  extraReducers: (builder) => {
    //--- GET SERVICES
    builder.addCase("book/getServices/pending", (state) => {
      state.loading = true;
    });
    builder.addCase("book/getServices/fulfilled", (state, { payload }) => {
      state.loading = false;
      state.services = payload?.data;
    });
    //--- GET Providers
    builder.addCase("book/getProviders/pending", (state) => {
      state.loadingProvider = true;
    });
    builder.addCase("book/getProviders/fulfilled", (state, { payload }) => {
      state.loadingProvider = false;
      state.provider = payload?.data;
      console.log(payload,'provider');
    });
    //--- GET OPEN
    builder.addCase("book/getOpen/pending", (state) => {
      state.loadingProvider = true;
    });
    builder.addCase("book/getOpen/fulfilled", (state, { payload }) => {
      state.loadingOpen = false;
      state.open = payload?.data;
    });
    builder.addCase("book/getOpen/rejected", (state, action) => {
      console.log(action);
      toast.error(action.payload);
      state.errorsMsg.open = action.payload;
    });
    //--- POST APPOINTMENT
    builder.addCase("book/bookAppoint/fulfilled", (state, { payload }) => {
      state.loadingOpen = false;
      toast.success("AppointMent sent successfully", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  },
});

// Action creators are generated for each case reducer function
export const { clearValue } = bookSlice.actions

export default bookSlice.reducer;
