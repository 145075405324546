import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AuthorProfile from "../elements/authorProfile";
import CommentList from "../elements/commentList";
import CommentRespond from "../elements/commentRespond";
import WidgetTag from "../elements/widgetTag";
import WidgetSearch from "../elements/widgetSearch";
import WidgetGallery from "../elements/widgetGallery";
import WidgetRecentPosts from "../elements/widgetRecentPosts";

// Import Images
import blogDefaultPic1 from "../../images/blog/default/pic1.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import galleryPic2 from "../../images/gallery/pic2.jpg";
import galleryPic5 from "../../images/gallery/pic5.jpg";

import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";

const BlogDetails = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const main = useSelector((state) => state.lang.value.blogDetails);
  const { blogDetails } = useSelector((state) => state.lang.value.pages);
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <HeroPage text={blogDetails[lang]} />

        <section className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-7 col-xl-8 mb-30 mb-md-50">
                <div className="blog-card blog-single">
                  <div className="post-media">
                    <img src={blogDefaultPic1} alt="" />
                  </div>
                  <div className="info-bx">
                    <ul className="post-meta">
                      <li className="author">
                        <Link to="/blog-details">
                          <img src={testPic3} alt="" />  {main.author[lang]}
                        </Link>
                      </li>
                      <li className="date">
                        <i className="far fa-calendar-alt"></i> 19 July 2021
                      </li>
                    </ul>
                    <div className="ttr-post-title">
                      <h2 className="post-title">
					  	{main.text[lang]}
                      </h2>
                    </div>
                    <div className="ttr-post-text">
                      <p>
					  {main.text2[lang]}
                      </p>
                      <blockquote className="wp-block-quote">
                        <p>
						{main.text3[lang]}
                        </p>
                      </blockquote>
                      <p>
					  {main.text4[lang]} 
                      </p>
                      <ul className="wp-block-gallery columns-6 is-cropped">
                        <li className="blocks-gallery-item">
                          <img alt="" src={galleryPic2} />
                        </li>
                        <li className="blocks-gallery-item">
                          <img alt="" src={galleryPic5} />
                        </li>
                      </ul>
                      <p>
					  {main.text5[lang]}
                      </p>
                      <p>
					  {main.text6[lang]}
                      </p>
                    </div>
                    <div className="ttr-post-footer">
                      <div className="post-tags">
                        <strong>{main.tags[lang]}</strong>
                        <Link to="#">{main.tagsNames.name1[lang]}</Link>
                        <Link to="#">{main.tagsNames.name1[lang]}</Link>
                        <Link to="#">{main.tagsNames.name1[lang]}</Link>
                      </div>
                      <div className="share-post ml-auto">
                        <ul className="social-media mb-0">
                          <li>
                            <strong>{main.share[lang]}:</strong>
                          </li>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.facebook.com/"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.instagram.com/"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.linkedin.com/"
                            >
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://twitter.com/"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <AuthorProfile />

                <div className="clear" id="comment-list">
                  <div className="comments-area" id="comments">
                    <h4 className="widget-title">8 {main.comments[lang]}</h4>

                    <div className="clearfix">
                      <CommentList />

                      <CommentRespond />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-5 col-xl-4 mb-30">
                <aside className="side-bar sticky-top aside-bx">
                  <WidgetSearch />

                  <WidgetRecentPosts />

                  <WidgetGallery />

                  <WidgetTag />
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default BlogDetails;
