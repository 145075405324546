import React from "react";

import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import Header from "../layout/header";
import aboutThumb1 from "../../images/about/pic-1.jpg";
import aboutThumb2 from "../../images/about/pic-2.jpg";
import aboutThumb3 from "../../images/about/pic-3.jpg";
import { useSelector } from "react-redux";
const See = () => {
    const lang ='ar';
    const main = useSelector((state) => state.lang.value.aboutSection);
    return (
        <>
            <Header color="text-secondary"/>
            <div className="container " style={{ marginTop: "100px" }}>
                <div className="row align-items-center">
                    <div className="col-lg-6 mb-30">
                        <div className="about-thumb-area">
                            <ul>
                                <li>
                                    <img className="about-thumb1" src={aboutThumb1} alt="" />
                                </li>
                                <li>
                                    <img className="about-thumb2" src={aboutThumb2} alt="" />
                                </li>
                                <li>
                                    <img className="about-thumb3" src={aboutThumb3} alt="" />
                                </li>
                                <li>
                                    <div className="exp-bx">
                                        20<span>Year Experience</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-30">
                        <div className="heading-bx ">
                            <h6 className="title-ext text-secondary">
                                الرؤيا
                            </h6>
                            <p>الريادة في تقديم الرعاية الصحية المتكاملة والمتميزة بأعلى معايير الجوده والسلامة</p>
                        </div>
                        {/* <Link to="/about-us" className="btn btn-primary shadow" style={{ marginTop: "30px" }}>
                            {main.button['ar']}
                        </Link> */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default See;