import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import servicesPic1 from "../../images/emergancy.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";

const ServicesDetailsOutDoctor = () => {

  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.servicesDetails);
  const mainRight = useSelector((state) => state.lang.value.servicesDetailsRight);
  const { servicesDetails } = useSelector((state) => state.lang.value.pages)

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <HeroPage text={servicesDetails[lang]} />

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-30">
                <div className="ttr-media mb-30 ">
                  <img src={servicesPic1} className="rounded w-full" alt="" />
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h2 className="title mb-15">
                      قسم العيادات الخارجية
                    </h2>
                    <p className="mb-0 fw-bold">
                    تمتاز العيادات الخارجية بتجهيزها بأحدث اﻷجهزة التشخيصية وهى تغطى التخصصات التالية:
                    </p>
                    <div>
                               جراحه مخ واعصاب-- 
                              جراحه عظام والمفاصل الصناعية--
                            --أمراض مخ وأعصاب
                            --النساء والتوليد
                              --الجراحه العامه 
                             --الجراحه العامه والمناظير
                             --جراحه الاوعيه الدموية 
                             --جراحه مخ واعصاب
                             --جراحه التجميل
                             --اﻷنف واﻷذن
                              --أمراض النفسية والعصبية
                             --علاج طبيعى
                             --رسم اﻷعصاب والعضلات
                              --أمراض القلب والشرايين
                             --أمراض الباطنية 
                             --أمراض الجهاز الهضمى والكبد 
                             --أمراض الاطفال 
                             جراحه الاطفال والعيوب الخلقية--
                             --التغذية العلاجيه 
                             --سمنه ونحافةوأطفال 
                             --اﻷسنان وجراحات الاسنان
                             --جراحه الوجه والفكين
                             --جراحه المسالك البولية

                    </div>
                  </div>
                  {/* <div className="row align-items-center">
                    <div className="col-md-6 mb-30">
                      <ul className="list-check-squer mb-0">
                        <li>{main.Detail1[lang]}</li>
                        <li>{main.Detail2[lang]}</li>
                        <li>{main.Detail3[lang]}</li>
                        <li>{main.Detail4[lang]}</li>
                        <li>{main.Detail5[lang]}</li>
                      </ul>
                    </div>
                    <div className="col-md-6 mb-30">
                      <div className="skillbar-box mb-30">
                        <h6 className="title">{main.progress1[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "96%" }}
                          ></p>
                          <span className="skill-bar-percent">79%</span>
                        </div>
                      </div>
                      <div className="skillbar-box mb-30">
                        <h6 className="title">{main.progress2[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "79%" }}
                          ></p>
                          <span className="skill-bar-percent">79%</span>
                        </div>
                      </div>
                      <div className="skillbar-box mb-0">
                        <h6 className="title">{main.progress3[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "75%" }}
                          ></p>
                          <span className="skill-bar-percent">75%</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">اﻹجراءات الطارئة</h4>
                    <p className="mb-0">
                      يتلقى جميع المرضى المقيمين الرعاية الطارئة على الفور يقوم ممرضونا المدربون تدريبا خاصا بتقييم شدة المرض أو الحادث بناء على اﻷعراض الرئيسية يتم تعيين واجراءات الاسعاف وفقا ﻹرشادات <span className="text-warning">وزارة الصحة المصرية</span>.
                    </p>
                  </div>
                 
                  <div>
                    <p className="mb-0 fw-bold"> معلومات العيادت الخارجية:</p>
                    <ul style={{ listStyleType: "circle" }}>
                        <li>يبدء الحجز الساعة 2 ظهرا يوميا إلى الساعة 9 مساء.</li>
                        <li>الحجز من خلال الموقع متاح 24 ساعة ويتم استلام رسالة تأكيد الحجز والاتصال من خلال خدمة العملاء من الساعة 9ص الى الساعة 9مساءا.</li>
                        <li>يبدء الرد على التليفون من الساعة 10صباحا إلى الساعة 2مساءا يوميا</li>
                        <li>اﻹستشارات الطبية خلال اسبوعين من تاريخ الكشف</li>
                        <li> يبدء المريض بالتوجه للاستعلامات للسؤال عن الطبيب إذا كان استشاره يتم إعطاءة رقم الدور ويتوجه المريض الى العيادة</li>
                        <li>يتم إدخال المرضى للعيادات عن طريق نظام رقمى ويكون الرقم بأولويه الحضور</li>
                        <li>إذا كان المريض كشف نقدي يتم التوجه إلى الخزينة للدفع ثم الرجوع إلى الاستعلامات لاخذ رقم الدور ثم التوجه إلى العيادة للكشف</li>
                        <li>وإذا كان مريض تابع لتأمين شركة يتوجه لعمل استمارة الكشف ثم إلى الاستعلامات لاخذ رقم الدور والتوجه للعيادة للكشف</li>
                        <li>متاح للمرضى او من ينوب الحضور إلى الاستعلامات في الصباح للحجز لطبيب يحضر في المساء ويتم عمل كافة اﻹجراءات معهم وإعطائهم رقم الدور</li>
                        <li>يتم الانتظار حتى حضور الطبيب امام العياده والدخول إلى الطبيب عند النداء على الرقم الخاص به </li>
                        <li>لا يتم تحديد رقم الدور إلا في نفس اليوم بحضور المريض أو من يتمم له الحجز ولا يتم أخذ الرقم بالتليفون أو بالنت أو فى يوم سابق الا فى حالات الحجز والدفع اونلاين من خلال <span className="text-warning">نظام الحجز بالموقع</span></li>
                        <li>برجاء اﻹتصال بمكتب الحجز قبل الحضور علما بأن ستصلكم رسالة على البريد الإليكترونى أو الهاتف فى حالة إعتذار الطبيب</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="sticky-top pb-1">
                  <div className="widget">
                    <ul className="service-menu">
                      <li className="">
                        <Link to="/service-detail-emergency">
                          <span>خدمات الطوارئ</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-surgery">
                          <span>الجراحة وغرف العمليات</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li className="active">
                        <Link to="/service-detail-outpatient-clinic">
                          <span>العيادات الخارجية</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-woman">
                          <span>النساء والولادة وعلاج العقم وتأخر الانجاب</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-baby">
                          <span>طب الاطفال وحديثى الولادة</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-boon">
                          <span>جراحة العظام</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-urinary-tract">
                          <span>المسالك البولية </span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-physical-therapy">
                          <span>العلاج الطبيعى</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-diagnostic-x-ray">
                          <span>اﻷشعة التشخيصية</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-lab">
                          <span>المعمل</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="widget">
                    <div className="brochure-bx">
                      <h5 className="title-head">{mainRight.downloadHeading[lang]}</h5>
                      <Link to="#" className="download-link">
                        <img src={pdf} alt="" />
                        <h5 className="title">{mainRight.file1[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                      <Link to="#" className="download-link">
                        <img src={doc} alt="" />
                        <h5 className="title">{mainRight.file2[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                    </div>
                  </div> */}
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default ServicesDetailsOutDoctor;
