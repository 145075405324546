import React from "react";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import LatestNewsSection from "../elements/latestNewsSlider";
import HeroPage from "../elements/heroPage";
// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

import { useSelector } from "react-redux";
const Faq = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const main = useSelector((state) => state.lang.value.faqSection);
  const { faq } = useSelector((state) => state.lang.value.pages);

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <HeroPage text={faq[lang]}/>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>

        <section className="section-sp3">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <Accordion
                  defaultActiveKey="0"
                  className="accordion ttr-accordion1"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{main.card1.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card1.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{main.card2.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card2.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>{main.card3.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card3.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{main.card4.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card4.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>{main.card5.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card5.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>{main.card6.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card6.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-lg-6">
                <Accordion
                  defaultActiveKey="2"
                  className="accordion ttr-accordion1"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{main.card7.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card7.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{main.card8.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card8.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>{main.card9.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card9.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{main.card10.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card10.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>{main.card11.que[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card1.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>{main.card12.ans[lang]}</Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">{main.card12.ans[lang]}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </section>

        <LatestNewsSection />
      </div>

      <Footer />
    </>
  );
};

export default Faq;
