import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HeroPage from "../elements/heroPage";
import pic1 from "../../images/about/pic-1.jpg";
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import FormData from "form-data";
import { PostFamilyCard, PostFamilyCardMembers } from "../../store/cartSlice";
const FamilyCart = () => {
    const formData = new FormData();
    const formFamily = new FormData();
    const lang = useSelector((state) => state.lang.value.lang);
    const main = useSelector((state) => state.lang.value.contact);
    const details = useSelector((state) => state.lang.value.contactDetails);
    const { contact } = useSelector((state) => state.lang.value.pages);

    const dispatch = useDispatch();
    const formikRef = useRef();

    const [initialValues, setInitialValues] = useState({
        name: "",
        phone: "",
        gender: "",
        address: "",
        city: "",
        governorate: "",
        birth_date: "",
        birth_city: "",
        national_number: "",
        job: "",
        company: "",
        peopleNumber: "",
        card_type: "",
        familyMembers: [],
        national_number_front_image: "",
        national_number_back_image: "",
        image: "",
    });
    // function objectToFormData(obj) {
    //     const formData = new FormData();
    //     for (const [key, value] of Object.entries(obj)) {
    //       formData.append(key, value);
    //       console.log(formData,"formData");
    //     }
    //     return formData;
    //   }
    const handleFormSubmit = (values, { resetForm }) => {
        if (values.familyMembers.length === 0) {
            formData.append("name", formikRef.current.values.name);
            formData.append("address", formikRef.current.values.address);
            formData.append("city", formikRef.current.values.city);
            formData.append("gender", formikRef.current.values.gender);
            formData.append("phone", formikRef.current.values.phone);
            formData.append("governorate", formikRef.current.values.governorate);
            formData.append("birth_date", formikRef.current.values.birth_date);
            formData.append("birth_city", formikRef.current.values.birth_city);
            formData.append("national_number", formikRef.current.values.national_number);
            formData.append("job", formikRef.current.values.job);
            formData.append("company", formikRef.current.values.company);
            formData.append("peopleNumber", formikRef.current.values.peopleNumber);
            formData.append("card_type", formikRef.current.values.card_type);
            formData.append("national_number_front_image", formikRef.current.values.national_number_front_image);
            formData.append("national_number_back_image", formikRef.current.values.national_number_back_image);
            formData.append("image", formikRef.current.values.image);
            dispatch(PostFamilyCard(formData));
        } else {
            formData.append("name", formikRef.current.values.name);
            formData.append("address", formikRef.current.values.address);
            formData.append("city", formikRef.current.values.city);
            formData.append("gender", formikRef.current.values.gender);
            formData.append("phone", formikRef.current.values.phone);
            formData.append("governorate", formikRef.current.values.governorate);
            formData.append("birth_date", formikRef.current.values.birth_date);
            formData.append("birth_city", formikRef.current.values.birth_city);
            formData.append("national_number", formikRef.current.values.national_number);
            formData.append("job", formikRef.current.values.job);
            formData.append("company", formikRef.current.values.company);
            formData.append("peopleNumber", formikRef.current.values.peopleNumber);
            formData.append("card_type", formikRef.current.values.card_type);
            formData.append("national_number_front_image", formikRef.current.values.national_number_front_image);
            formData.append("national_number_back_image", formikRef.current.values.national_number_back_image);
            formData.append("image", formikRef.current.values.image);
            for (let i = 0; i < values.familyMembers.length; i++) {
                formFamily.append("name", values.familyMembers[i].name);
                formFamily.append("gender", values.familyMembers[i].gender);
                formFamily.append("national_number", values.familyMembers[i].national_number);
                formFamily.append("national_number_front_image", values.familyMembers[i].national_number_front_image);
                formFamily.append("national_number_back_image", values.familyMembers[i].national_number_back_image);
                formFamily.append("image", values.familyMembers[i].image);
            }
            formFamily.append("family_card", values.familyMembers.length);

            dispatch(PostFamilyCard(formData));
            dispatch(PostFamilyCardMembers(formFamily));
        }

;        resetForm({ values: initialValues });
        // const family = values.familyMembers; // Remove 'familyMembers' property
        // const peopleNum = values.peopleNumber;
        // delete values.peopleNumber;
        // delete values.familyMembers;
        // console.log(values, "rest");
    };

    useEffect(() => {
        if (initialValues.peopleNumber) {
            const familyMembers = Array.from({ length: initialValues.peopleNumber }, (_, index) => ({
                name: "",
                gender: "",
                national_number: "",
                national_number_front_image: "",
                national_number_back_image: "",
                image: ""
            }));
            setInitialValues(prevState => ({
                ...prevState,
                familyMembers
            }));
        }
    }, [initialValues.peopleNumber]);
    const state = [{ name: "أسوان" }, { name: "أسيوط" }, { name: "الإسكندرية" }, { name: "الإسماعيلية" }, { name: "الأقصر" }, { name: "البحر الأحمر" }, { name: "البحيرة" }, { name: "الجيزة" }, { name: "الدقهلية" }, { name: "السويس" }, { name: "الشرقية" }, { name: "الغربية" }, { name: "الفيوم" }, { name: "القليوبية" }, { name: "القاهرة" }, { name: "المنوفية" }, { name: "المنيا" }, { name: "الوادي الجديد" }, { name: "بني سويف" }, { name: "بورسعيد" }, { name: "جنوب سيناء" }, { name: "دمياط" }, { name: "سوهاج" }, { name: "شمال سيناء" }, { name: "قنا" }, { name: "كفر الشيخ" }, { name: "مطروح" }]

    const compressImage = async (file, { quality = 1, type = file.type }) => {
        // Get as image data
        const imageBitmap = await createImageBitmap(file);

        // Draw to canvas
        const canvas = document.createElement('canvas');
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(imageBitmap, 0, 0);

        // Turn into Blob
        const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, type, quality)
        );

        // Turn Blob into File
        return new File([blob], file.name, {
            type: blob.type,
        });
    };
    const handleFrontFileChange = async (event) => {
        const file = event.target.files[0];
        // We'll store the files in this data transfer object
        if (file?.type === "image/webp") {
            formikRef.current.setFieldValue("national_number_front_image", null);
        } else {
            if (file) {
                const dataTransfer = new DataTransfer();
                dataTransfer?.items?.add(file);
                const compressedFile = await compressImage(file, {
                    quality: 0.1,
                    type: 'image/jpeg',
                });
                if (formikRef.current) {
                    formikRef.current.setFieldValue("national_number_front_image", compressedFile);
                }
            }else{
                formikRef.current.setFieldValue("national_number_front_image", "");
            }
        }
    };
    const handleBackFileChange = async (event) => {
        const file = event.target.files[0];
        if (file?.type === "image/webp") {
            formikRef.current.setFieldValue("national_number_back_image", null);
        } else {
            if (file) {
                const dataTransfer = new DataTransfer();
                dataTransfer?.items?.add(file);
                const compressedFile = await compressImage(file, {
                    quality: 0.1,
                    type: 'image/jpeg',
                });
                if (formikRef.current) {
                    formikRef.current.setFieldValue("national_number_back_image", compressedFile);
                }
            }else{
                formikRef.current.setFieldValue("national_number_back_image", "");
            }
        }

    };
    const handlePersonalImageChange = async (event) => {
        const file = event.target.files[0];
        if (file?.type === "image/webp") {
            formikRef.current.setFieldValue("image", null);

        } else {
            if (file) {
                const dataTransfer = new DataTransfer();
                dataTransfer?.items?.add(file);
                const compressedFile = await compressImage(file, {
                    quality: 0.1,
                    type: 'image/jpeg',
                });
                if (formikRef.current) {
                    formikRef.current.setFieldValue("image", compressedFile);
                }
            }else{
                formikRef.current.setFieldValue("image", "");
            }

        }
    };
    const handleFrontFileChangepeople = async (event, index) => {
        const file = event.target.files[0];
        if (file?.type === "image/webp") {
            formikRef.current.setFieldValue(`familyMembers.${index}.national_number_front_image`, null);
        } else {
            if (file) {
                const dataTransfer = new DataTransfer();
                dataTransfer?.items?.add(file);
                const compressedFile = await compressImage(file, {
                    quality: 0.1,
                    type: 'image/jpeg',
                });
                if (formikRef.current) {
                    formikRef.current.setFieldValue(`familyMembers.${index}.national_number_front_image`, compressedFile);
                }
            }else{
                formikRef.current.setFieldValue(`familyMembers.${index}.national_number_front_image`, "");
            }
        }
    }
    const handleBackFileChangepeople = async (event, index) => {
        const file = event.target.files[0];

        if (file?.type === "image/webp") {
            formikRef.current.setFieldValue(`familyMembers.${index}.national_number_back_image`, null);
        } else {
            if (file) {
                const dataTransfer = new DataTransfer();
                dataTransfer?.items?.add(file);
                const compressedFile = await compressImage(file, {
                    quality: 0.1,
                    type: 'image/jpeg',
                });
                if (formikRef.current) {
                    formikRef.current.setFieldValue(`familyMembers.${index}.national_number_back_image`, compressedFile);
                }
            }else{
                formikRef.current.setFieldValue(`familyMembers.${index}.national_number_back_image`, "");
            }
        }
    };
    const handlePersonalImageChangepeople = async (event, index) => {
        const file = event.target.files[0];
        if (file?.type === "image/webp") {
            formikRef.current.setFieldValue(`familyMembers.${index}.image`, null);
        } else {
            if (file) {
                const dataTransfer = new DataTransfer();
                dataTransfer?.items?.add(file);
                const compressedFile = await compressImage(file, {
                    quality: 0.1,
                    type: 'image/jpeg',
                });
                if (formikRef.current) {
                    formikRef.current.setFieldValue(`familyMembers.${index}.image`, compressedFile);
                }
            }else{
                formikRef.current.setFieldValue(`familyMembers.${index}.image`, "");
            }
        }
    };

    return (
        <>
            <Header />

            <div className="page-content bg-white">
                <HeroPage
                    text={contact[lang]}
                    className={"page-banner banner-lg contact-banner"}
                />

                <section className="">
                    <div className="container">
                        <div className="contact-wraper">
                            <div className="row">
                                <div className="col-lg-6 mb-30">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={checkoutSchema}
                                        onSubmit={handleFormSubmit}
                                        innerRef={formikRef}
                                        enableReinitialize
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue,
                                        }) => (
                                            <form
                                                onSubmit={handleSubmit}
                                                className="form-wraper contact-form ajax-form"
                                            >
                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                            className="form-control"
                                                            placeholder={"الاسم رباعى"}
                                                        />
                                                        <span className="error">
                                                            {errors.name && touched.name && errors.name}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <select id="gender" name="gender" value={values.gender} onChange={handleChange} className="form-control">
                                                            <option value="">-- اختر النوع --</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                        <span className="error">
                                                            {errors.gender && touched.gender && errors.gender}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="text"
                                                            name="address"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.address}
                                                            className="form-control"
                                                            placeholder={"العنوان"}
                                                        />
                                                        <span className="error">
                                                            {errors.address && touched.address && errors.address}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <select id="governorate" name="governorate" value={values.governorate} onChange={handleChange} className="form-control">
                                                            <option value="">-- اختر المحافظه --</option>
                                                            {state.map((item, index) => (
                                                                <option key={index} value={item.name}>{item.name}</option>
                                                            ))}

                                                        </select>
                                                        <span className="error">
                                                            {errors.governorate && touched.governorate && errors.governorate}
                                                        </span>
                                                    </div>

                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="text"
                                                            name="city"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.city}
                                                            className="form-control"
                                                            placeholder={"المدينة"}
                                                        />
                                                        <span className="error">
                                                            {errors.city && touched.city && errors.city}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="date"
                                                            name="birth_date"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.birth_date}
                                                            className="form-control"
                                                            placeholder={"تاريخ الميلاد"}
                                                        />
                                                        <span className="error">
                                                            {errors.birth_date && touched.birth_date && errors.birth_date}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="text"
                                                            name="birth_city"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.birth_city}
                                                            className="form-control"
                                                            placeholder={" محل الميلاد"}
                                                        />
                                                        <span className="error">
                                                            {errors.birth_city && touched.birth_city && errors.birth_city}
                                                        </span>
                                                    </div>

                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="number"
                                                            name="national_number"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.national_number}
                                                            className="form-control"
                                                            placeholder={" الرقم القومى "}
                                                        />
                                                        <span className="error">
                                                            {errors.national_number && touched.national_number && errors.national_number}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="text"
                                                            name="job"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.job}
                                                            className="form-control"
                                                            placeholder={"المهنة"}
                                                        />
                                                        <span className="error">
                                                            {errors.job && touched.job && errors.job}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="text"
                                                            name="company"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.company}
                                                            className="form-control"
                                                            placeholder={" جهه العمل "}
                                                        />
                                                        <span className="error">
                                                            {errors.company && touched.company && errors.company}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.phone}
                                                            className="form-control"
                                                            placeholder={"رقم الهاتف"}
                                                        />
                                                        <span className="error">
                                                            {errors.phone && touched.phone && errors.phone}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label className="fw-bold">صورة البطاقة الشخصية الامامية:</label>
                                                        <input
                                                            type="file"
                                                            name="national_number_front_image"
                                                            onChange={handleFrontFileChange}
                                                            onBlur={handleBlur}
                                                            className="form-control"
                                                            accept="image/png, image/jpeg, image/jpg"                                                            placeholder={"صورة البطاقة الشخصية الامامية"}
                                                        />
                                                        <span className="error">
                                                            {errors.national_number_front_image && touched.national_number_front_image && errors.national_number_front_image}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label className="fw-bold">صورة البطاقة الشخصية الخلفية:</label>

                                                        <input
                                                            type="file"
                                                            name="national_number_back_image"
                                                            onChange={handleBackFileChange}
                                                            onBlur={handleBlur}
                                                            className="form-control"
                                                            accept="image/png, image/jpeg, image/jpg"                                                            placeholder={"صورة البطاقة الشخصية الخلفية"}
                                                        />
                                                        <span className="error">
                                                            {errors.national_number_back_image && touched.national_number_back_image && errors.national_number_back_image}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label className="fw-bold">صورة  شخصية :</label>

                                                        <input
                                                            type="file"
                                                            name="image"
                                                            onChange={handlePersonalImageChange}
                                                            onBlur={handleBlur}
                                                            className="form-control"
                                                            placeholder={"صورة شخصية"}
                                                            accept="image/png, image/jpeg, image/jpg"
                                                            />                                                        
                                                        <span className="error">
                                                            {errors.image && touched.image && errors.image}
                                                        </span>
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <select id="card_type" name="card_type" value={values.card_type} onChange={handleChange} className="form-control">
                                                            <option value="">-- اختر الكارت --</option>
                                                            <option value="individual">فردى</option>
                                                            <option value="family">عيله</option>
                                                        </select>

                                                        <span className="error">
                                                            {errors.card_type && touched.card_type && errors.card_type}
                                                        </span>
                                                    </div>
                                                    {values.card_type === 'family' && (
                                                        <div className="form-group col-md-12">
                                                            <input
                                                                type="number"
                                                                name="peopleNumber"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    setFieldValue('peopleNumber', e.target.value);
                                                                    const numberOfPeople = parseInt(e.target.value, 10);
                                                                    if (!isNaN(numberOfPeople)) {
                                                                        setFieldValue('familyMembers', Array.from({ length: numberOfPeople }, () => ({
                                                                            name: "",
                                                                            gender: "",
                                                                            national_number: "",
                                                                            national_number_front_image: "",
                                                                            national_number_back_image: "",
                                                                            image: ""
                                                                        })));
                                                                    }
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.peopleNumber}
                                                                className="form-control"
                                                                placeholder="عدد الأفراد"
                                                            />
                                                            <span className="error">
                                                                {errors.peopleNumber && touched.peopleNumber && errors.peopleNumber}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {values.familyMembers.length > 0 && values.card_type === 'family' && (
                                                        <FieldArray
                                                            name="familyMembers"
                                                            render={(arrayHelpers) => (
                                                                values.familyMembers.map((_, index) => (
                                                                    <div key={index} className="form-group col-md-12">
                                                                        <h5>الشخص {index + 1}</h5>
                                                                        <input
                                                                            type="text"
                                                                            name={`familyMembers.${index}.name`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.familyMembers[index].name}
                                                                            className="form-control mt-2"
                                                                            placeholder={`الاسم رباعى الفرد ${index + 1}`}
                                                                        />
                                                                        <span className="error">
                                                                            {errors.familyMembers && errors.familyMembers[index] && errors.familyMembers[index].name && touched.familyMembers && touched.familyMembers[index] && touched.familyMembers[index].name && errors.familyMembers[index].name}
                                                                        </span>
                                                                        <select
                                                                            name={`familyMembers.${index}.gender`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.familyMembers[index].gender}
                                                                            className="form-control mt-3"
                                                                        >
                                                                            <option value="">-- اختر النوع --</option>
                                                                            <option value="male">Male</option>
                                                                            <option value="female">Female</option>
                                                                        </select>
                                                                        <span className="error">
                                                                            {errors.familyMembers && errors.familyMembers[index] && errors.familyMembers[index].gender && touched.familyMembers && touched.familyMembers[index] && touched.familyMembers[index].gender && errors.familyMembers[index].gender}
                                                                        </span>

                                                                        <input
                                                                            type="text"
                                                                            name={`familyMembers.${index}.national_number`}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.familyMembers[index].national_number}
                                                                            className="form-control mt-3"
                                                                            placeholder={`الرقم القومى الفرد ${index + 1}`}
                                                                        />
                                                                        <p className="error">
                                                                            {errors.familyMembers && errors.familyMembers[index] && errors.familyMembers[index].national_number && touched.familyMembers && touched.familyMembers[index] && touched.familyMembers[index].national_number && errors.familyMembers[index].national_number}
                                                                        </p>

                                                                        <label className="fw-bold mt-3">صورة  البطاقة الشخصية الامامية :</label>
                                                                        <input
                                                                            type="file"
                                                                            name={`familyMembers.${index}.national_number_front_image`}
                                                                            onChange={(e) => handleFrontFileChangepeople(e, index)}
                                                                            // value={values.familyMembers[index].national_number_front_image}
                                                                            onBlur={handleBlur}
                                                                            className="form-control "
                                                                            placeholder={"صورة البطاقة الشخصية الامامية"}
                                                                            accept="image/png, image/jpeg, image/jpg"                                                                        />
                                                                        {/* <p className="error">
                                                                            {errors.familyMembers && errors.familyMembers[index] && errors.familyMembers[index].national_number_front_image && touched.familyMembers && touched.familyMembers[index] && touched.familyMembers[index].national_number_front_image && errors.familyMembers[index].national_number_front_imagenational_number_front_image}
                                                                        </p> */}
                                                                        <label className="fw-bold mt-3">صورة  البطاقة الشخصية الخلفية :</label>
                                                                        <input
                                                                            type="file"
                                                                            name={`familyMembers.${index}.national_number_back_image`}
                                                                            onChange={(e) => handleBackFileChangepeople(e, index)}
                                                                            onBlur={handleBlur}
                                                                            // value={values.familyMembers[index].national_number_back_image}
                                                                            className="form-control "
                                                                            placeholder={"صورة البطاقة الشخصية الخلفية"}
                                                                            accept="image/png, image/jpeg, image/jpg"                                                                        />
                                                                        {/* {errors.familyMembers[index].national_number_back_image && <p className="error">
                                                                            {errors.familyMembers && errors.familyMembers[index] && errors.familyMembers[index].national_number_back_image && touched.familyMembers && touched.familyMembers[index] && touched.familyMembers[index].national_number_back_image && errors.familyMembers[index].national_number_back_image}
                                                                        </p>} */}

                                                                        <label className="fw-bold mt-3">صورة  شخصية :</label>
                                                                        <input
                                                                            type="file"
                                                                            name={`familyMembers.${index}.image`}
                                                                            onChange={(e) => handlePersonalImageChangepeople(e, index)}
                                                                            onBlur={handleBlur}
                                                                            className="form-control "
                                                                            placeholder={"صورة شخصية"}
                                                                            accept="image/png, image/jpeg, image/jpg"
                                                                            />                                                                        
                                                                        <span className="error">
                                                                            {errors.familyMembers && errors.familyMembers[index] && errors.familyMembers[index].image && touched.familyMembers && touched.familyMembers[index] && touched.familyMembers[index].image && errors.familyMembers[index].image}
                                                                        </span>
                                                                    </div>
                                                                ))
                                                            )}
                                                        />
                                                    )}
                                                    <div className="col-lg-12">
                                                        <button
                                                            name="submit"
                                                            type="submit"
                                                            value="Submit"
                                                            className="btn w-100 btn-secondary btn-lg"
                                                            disabled={isSubmitting}
                                                        >
                                                            {main.button[lang]}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                                <div className="col-lg-6 mb-30">
                                    <div
                                        className="contact-info ovpr-dark"
                                        style={{ backgroundImage: "url(" + pic1 + ")" }}
                                    >
                                        <div className="info-inner">
                                            <h4 className="title mb-30">{main.text[lang]}</h4>
                                            <div className="icon-box">
                                                <h6 className="title">
                                                    <i className="ti-map-alt"></i>
                                                    {main.locationName[lang]}
                                                </h6>
                                                <p>{main.location[lang]}</p>
                                            </div>
                                            <div className="icon-box">
                                                <h6 className="title">
                                                    <i className="ti-id-badge"></i>
                                                    {main.emailHeading[lang]}
                                                </h6>
                                                <Link to="#" className="text-white">
                                                    {main.emailText[lang]}
                                                </Link>
                                                <p>{main.phoneNumber[lang]}</p>
                                            </div>
                                            <div className="icon-box">
                                                <h6 className="title">
                                                    <i className="ti-world"></i>
                                                    {main.follow[lang]}
                                                </h6>
                                                <ul className="social-media">
                                                    <li>
                                                        <a
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            href="https://www.facebook.com/Elagouz.Hospital/"
                                                        >
                                                            <i className="fab fa-facebook"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            href="https://youtube.com/channel/UCZqQMivKhy2gKcMCq3X6WdQ"
                                                        >
                                                            <i className="fab fa-youtube"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            href="https://instagram.com/elagouz_hospital?igshid=YmMyMTA2M2Y"
                                                        >
                                                            <i className="fab fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="section-area section-sp1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="feature-container feature-bx4 feature4">
                                    <div className="icon-md feature-icon">
                                        <img src={icon1} alt="" />
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-title">{details.contactNumber[lang]}</h5>
                                        <p>01111149544</p>
                                        <p>01222273236</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="feature-container feature-bx4 feature3">
                                    <div className="icon-md feature-icon">
                                        <img src={icon3} alt="" />
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-title">{details.email[lang]}</h5>
                                        <p>AgouzHospital2000@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="feature-container feature-bx4 feature2">
                                    <div className="icon-md feature-icon">
                                        <img src={icon2} alt="" />
                                    </div>
                                    <div className="icon-content">
                                        <h5 className="ttr-title">
                                            {details.address.heading[lang]}
                                        </h5>
                                        <p>{details.address.text[lang]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>

            <Footer />
        </>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    name: yup.string()
        .test(
            'has-three-spaces',
            'يرجى إدخال الاسم رياعي',
            (value) => {
                // Ensure the value is a string
                if (!value || typeof value !== 'string') return false;

                // Count the number of spaces
                const spaceCount = (value.match(/ /g) || []).length;

                // Return true if there are exactly three spaces
                return spaceCount >= 3;
            }
        )
        .min(11, "يرجي ادخال الاسم الرباعي صحيح ")
        .required(" يرجى إدخال الاسم رياعي "),
    phone: yup.string()
        .matches(phoneRegExp, "  رقم الهاتف غير صحيح ")
        .required("يرجى إدخال رقم الهاتف"),
    gender: yup.string().required("مطلوب"),
    address: yup.string().required("مطلوب"),
    city: yup.string().required("مطلوب"),
    governorate: yup.string().required("مطلوب"),
    birth_date: yup.string().required(" تاريخ الميلادمطلوب"),
    birth_city: yup.string().required("مطلوب"),
    national_number: yup.string()
        .matches(/^\d{14}$/, "يجب أن يحتوي رقم البطاقة على 14 رقمًا فقط")
        .required("يرجى إدخال رقم البطاقة"),
    job: yup.string().required("مطلوب"),
    company: yup.string().required("مطلوب"),
    card_type: yup.string().required("مطلوب"),
    peopleNumber: yup.number().when('card_type', {
        is: 'family',
        then: yup.number().required("مطلوب")
    }),
    national_number_front_image: yup.string().required('مطلوب'),
    national_number_back_image: yup.string().required('مطلوب'),
    image: yup.string().required('مطلوب'),

    familyMembers: yup.array().of(
        yup.object().shape({
            name: yup.string()
                .test(
                    'has-three-spaces',
                    'يرجى إدخال الاسم رياعي',
                    (value) => {
                        // Ensure the value is a string
                        if (!value || typeof value !== 'string') return false;

                        // Count the number of spaces
                        const spaceCount = (value.match(/ /g) || []).length;

                        // Return true if there are exactly three spaces
                        return spaceCount >= 3;
                    }
                )
                .min(11, "يرجي ادخال الاسم الرباعي صحيح ")
                .required(" يرجى إدخال الاسم رياعي "),
            gender: yup.string().required("مطلوب"),
            national_number: yup.string()
                .matches(/^\d{14}$/, "يجب أن يحتوي رقم البطاقة على 14 رقمًا فقط")
                .required("يرجى إدخال رقم البطاقة"),
            image: yup.string().required('مطلوب'),
        })
    )
});

export default FamilyCart;