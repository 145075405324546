import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import HeroPage from "../elements/heroPage";
// Import Images
import pic1 from "../../images/about/pic-1.jpg";
import icon1 from "../../images/icon/icon1.png";
import icon2 from "../../images/icon/icon2.png";
import icon3 from "../../images/icon/icon3.png";
/* import redux*/
import { useDispatch, useSelector } from "react-redux";
import { contactUser } from "../../store/contactSlice";
// import yup
import * as yup from "yup";

const ContactSection = () => {
  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.contact);
  const details = useSelector((state) => state.lang.value.contactDetails);
  const contactHeading = useSelector((state) => state.lang.value.contactHeading);
  const { contact } = useSelector((state) => state.lang.value.pages);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    name: "",
    organization: "Agouz Hospital",
    email: "",
    message: "",
    phone: "",
    service: "",
  });

  const handleFormSubmit = (values, { resetForm }) => {
    dispatch(contactUser(values));
    resetForm({ values: "" });
  };
  return (
    <>
      <div className="heading-bx text-center" id="contact">
        {/* <h6 className="title-ext text-secondary">{contactHeading.heading['ar']}</h6> */}
        <h2 className="title">{contactHeading.heading['ar']}</h2>
      </div>
      <div className="page-content bg-white">
        {/* <section className="">
          <div className="container">
            <div className="contact-wraper" style={{marginTop: 0}}>
              <div className="row">
                <div className="col-lg-6 mb-30">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="form-wraper contact-form ajax-form"
                      >
                        <div className="row">
                          <div className="form-group col-md-12">
                            <input
                              type="name"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              className="form-control"
                              placeholder={main.name['ar']}
                            />
                            <span className="error">
                              {errors.name && touched.name && errors.name}
                            </span>
                          </div>
                          <div className="form-group col-md-12">
                            <input
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              className="form-control"
                              placeholder={main.email['ar']}
                            />
                            <span className="error">
                              {errors.email && touched.email && errors.email}
                            </span>
                          </div>
                          <div className="form-group col-md-12">
                            <input
                              type="text"
                              name="phone"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                              className="form-control"
                              placeholder={main.phone['ar']}
                            />
                            <span className="error">
                              {errors.phone && touched.phone && errors.phone}
                            </span>
                          </div>
                          <div className="form-group col-md-12">
                            <textarea
                              name="message"
                              className="form-control"
                              placeholder="Type Message"
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            ></textarea>
                            <span className="error">
                              {errors.message &&
                                touched.message &&
                                errors.message}
                            </span>
                          </div>

                          <div className="col-lg-12">
                            <button
                              name="submit"
                              type="submit"
                              value="Submit"
                              className="btn w-100 btn-secondary btn-lg"
                              disabled={isSubmitting}
                            >
                              {main.button['ar']}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
                <div className="col-lg-6 mb-30">
                  <div
                    className="contact-info ovpr-dark"
                    style={{ backgroundImage: "url(" + pic1 + ")" }}
                  >
                    <div className="info-inner">
                      <h4 className="title mb-30">{main.text['ar']}</h4>
                      <div className="icon-box">
                        <h6 className="title">
                          <i className="ti-map-alt"></i>
                          {main.locationName['ar']}
                        </h6>
                        <p>{main.location['ar']}</p>
                      </div>
                      <div className="icon-box">
                        <h6 className="title">
                          <i className="ti-id-badge"></i>
                          {main.emailHeading['ar']}
                        </h6>
                        <Link to="#" className="text-white">
                          {main.emailText['ar']}
                        </Link>
                        <p>{main.phoneNumber['ar']}</p>
                      </div>
                      <div className="icon-box">
                        <h6 className="title">
                          <i className="ti-world"></i>
                          {main.follow['ar']}
                        </h6>
                        <ul className="social-media">
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://www.facebook.com/Elagouz.Hospital/"
                            >
                              <i className="fab fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://youtube.com/channel/UCZqQMivKhy2gKcMCq3X6WdQ"
                            >
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href="https://instagram.com/elagouz_hospital?igshid=YmMyMTA2M2Y"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="section-area ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature4">
                  <div className="icon-md feature-icon">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">{details.contactNumber['ar']}</h5>
                    <p>01111149544</p>
                    <p>01222273236</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature3">
                  <div className="icon-md feature-icon">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">{details.email['ar']}</h5>
                    <p>AgouzHospital2000@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="feature-container feature-bx4 feature2">
                  <div className="icon-md feature-icon">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-title">
                      {details.address.heading['ar']}
                    </h5>
                    <p>{details.address.text['ar']}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.9365033741065!2d31.201562400000004!3d30.0960048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145841bb6b4c372b%3A0x6b853aa1631a7e48!2z2YXYs9iq2LTZgdmJINin2YTYudis2YjYsiDYp9mE2KrYrti12LXZiQ!5e0!3m2!1sen!2seg!4v1685868445199!5m2!1sen!2seg"
        width="100%"
        title="Gray's and Danny's Meat Plant"
        height="389px"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>

    </>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  message: yup.string().required("required"),
});

export default ContactSection;
