import { configureStore } from "@reduxjs/toolkit";
import langSlice from "./store/langSlice";
import contactSlice from "./store/contactSlice";
// import bookSlice from "./store/bookSlice";

import bookSlice from "./store/book/bookSlice";
export const store = configureStore({
  reducer: {
    lang: langSlice,
    contact: contactSlice,
    book: bookSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
