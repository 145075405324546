import React from "react";
import { useSelector } from "react-redux";
// Import Images
import testPic2 from "../../images/testimonials/pic2.jpg";

const AuthorProfile = () => {
  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.blogDetails);
  return (
    <>
      <div className="author-box blog-user mb-50">
        <div className="author-profile-info">
          <div className="author-profile-pic">
            <img src={testPic2} alt="" />
          </div>
          <div className="author-profile-content">
            <h5>{main.mainComment.author['ar']} </h5>
            <p className="mb-20">
              {main.mainComment.text['ar']}
            </p>
            <ul className="social-media mb-0">
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorProfile;
