import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import servicesPic1 from "../../images/emergancy.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";

const ServicesDetailsEmergency = () => {

  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.servicesDetails);
  const mainRight = useSelector((state) => state.lang.value.servicesDetailsRight);
  const { servicesDetails } = useSelector((state) => state.lang.value.pages)

  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <HeroPage text={servicesDetails[lang]} />

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mb-30">
                <div className="ttr-media mb-30 ">
                  <img src={servicesPic1} className="rounded w-full" alt="" />
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h2 className="title mb-15">
                      قسم الطوارئ
                    </h2>
                    <p className="mb-0">
                      ‫يوفر‬ ‫القسم ‫مجموعة ‫من‬ ‫الخدمات‬ ‫العلاجية‬ بدءا ‬‫من‬ ‫إدارة ‬ ‫اﻹجراءات‬ ‫الجراحية‬ ‫الصغرى‬ ‫مثل‬ ‫قطب‬ ‫الجروح‪،‬‬ ‫وصولا  ‫إلى‬ إدارة‬ ‫الحالات‬ ‫الخطيرة‬ ‫مثل اﻹصابات‬ ‫المتعددة‬ ‫وغير‬ ‫ذلك‬ ‫من‬ ‫حالات‬ ‫الجراحة‬ ‫الطارئة‪.‬‬
                    </p>
                  </div>
                  {/* <div className="row align-items-center">
                    <div className="col-md-6 mb-30">
                      <ul className="list-check-squer mb-0">
                        <li>{main.Detail1[lang]}</li>
                        <li>{main.Detail2[lang]}</li>
                        <li>{main.Detail3[lang]}</li>
                        <li>{main.Detail4[lang]}</li>
                        <li>{main.Detail5[lang]}</li>
                      </ul>
                    </div>
                    <div className="col-md-6 mb-30">
                      <div className="skillbar-box mb-30">
                        <h6 className="title">{main.progress1[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "96%" }}
                          ></p>
                          <span className="skill-bar-percent">79%</span>
                        </div>
                      </div>
                      <div className="skillbar-box mb-30">
                        <h6 className="title">{main.progress2[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "79%" }}
                          ></p>
                          <span className="skill-bar-percent">79%</span>
                        </div>
                      </div>
                      <div className="skillbar-box mb-0">
                        <h6 className="title">{main.progress3[lang]}</h6>
                        <div className="skillbar appear">
                          <p
                            className="skillbar-bar"
                            style={{ width: "75%" }}
                          ></p>
                          <span className="skill-bar-percent">75%</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="clearfix">
                  <div className="head-text mb-30">
                    <h4 className="title mb-10">اﻹجراءات الطارئة</h4>
                    <p className="mb-0">
                      يتلقى جميع المرضى المقيمين الرعاية الطارئة على الفور يقوم ممرضونا المدربون تدريبا خاصا بتقييم شدة المرض أو الحادث بناء على اﻷعراض الرئيسية يتم تعيين واجراءات الاسعاف وفقا ﻹرشادات <span className="text-warning">وزارة الصحة المصرية</span>.
                    </p>
                  </div>
                  {/* <Accordion
                    defaultActiveKey="0"
                    className="accordion ttr-accordion1"
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {main.card1.que[lang]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                          {main.card1.ans[lang]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {main.card2.que[lang]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                          {main.card2.ans[lang]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        {main.card3.que[lang]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                          {main.card3.ans[lang]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        {main.card4.que[lang]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                          {main.card4.ans[lang]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        {main.card5.que[lang]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                          {main.card5.ans[lang]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        {main.card6.que[lang]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                          {main.card6.ans[lang]}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}
                  <div>
                    <p className="mb-0 fw-bold">أوقات اﻹنتظار:</p>
                    <p>في بعض أوقات الذروة قد يكون هناك انتظار بسبب العدد الكبير من المرضى الذين يحتاجون إلى علا . نحاول دائما أن نجعل هذا الوقت فصيرا قدر اﻹمكان. ودائما نبلغ المرضى بإنتظام عن الوضع الحالى.</p>
                    <p className="mb-0 fw-bold">الفحص الطبي والعلاج اﻷولي:</p>
                    <p>يقوم فريق اﻷطباء المتخصصين بفحص مرضى الطوارئ بسرعه ويبدأوا في إجراء المزيد من التقييمات ومراحل العلاج اللازمة بالتعاون المستمر مع فريق التمريض.</p>
                    <p className="mb-0 fw-bold">إشتراك اﻷخصائيين ومتابعة العلاج:</p>
                    <p>يمكننا استدعاء المتخصصين بسرعه وإجراء العلاج المعقد في مستشفى العجوز التخصصى في أي وقت إذا لزم اﻷمر طبيبا يمكننا علاج المرضى على الفور في العيادات المتخصصة بالمستشفى.</p>
                    <p className="mb-0 fw-bold">المسار العلاجى السريع:</p>
                    <p>الهدف من المسار العلاجى السريع هو توفير علاج سريع ومتخصص. في منطقة العلاج المنفصلة حيث نعالج جميع المرضى الذين يعانون من حالات طوارئ بسيطة وتتطلب موارد أقل في الاماكن المخصصة لذلك.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="sticky-top pb-1">
                  <div className="widget">
                    <ul className="service-menu">
                      <li className="active">
                        <Link to="/service-detail-emergency">
                          <span>خدمات الطوارئ</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-surgery">
                          <span>الجراحة وغرف العمليات</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-outpatient-clinic">
                          <span>العيادات الخارجية</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-woman">
                          <span>النساء والولادة وعلاج العقم وتأخر الانجاب</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-baby">
                          <span>طب الاطفال وحديثى الولادة</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-boon">
                          <span>جراحة العظام</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-urinary-tract">
                          <span>المسالك البولية </span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-physical-therapy">
                          <span>العلاج الطبيعى</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-diagnostic-x-ray">
                          <span>اﻷشعة التشخيصية</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/service-detail-lab">
                          <span>المعمل</span>
                          <i className="fa fa-angle-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="widget">
                    <div className="brochure-bx">
                      <h5 className="title-head">{mainRight.downloadHeading[lang]}</h5>
                      <Link to="#" className="download-link">
                        <img src={pdf} alt="" />
                        <h5 className="title">{mainRight.file1[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                      <Link to="#" className="download-link">
                        <img src={doc} alt="" />
                        <h5 className="title">{mainRight.file2[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                    </div>
                  </div> */}
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default ServicesDetailsEmergency;
