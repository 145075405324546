import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper.scss";
import 'swiper/swiper-bundle.min.css';
import { Autoplay, A11y } from "swiper";

import client1 from "../../images/partenrs/1.jpg";
import client2 from "../../images/partenrs/2.jpg";
import client3 from "../../images/partenrs/3.jpg";
import client4 from "../../images/partenrs/4.jpg";
import client5 from "../../images/partenrs/5.jpg";
import client6 from "../../images/partenrs/6.jpg";
import client7 from "../../images/partenrs/7.png";
import client8 from "../../images/partenrs/8.png";
import client9 from "../../images/partenrs/9.png";

import client10 from "../../images/partenrs/10.png";
import client11 from "../../images/partenrs/11.png";
import client12 from "../../images/partenrs/12.png";
import client13 from "../../images/partenrs/13.png";
import client14 from "../../images/partenrs/14.png";
import Slider from "react-slick";
const OurClients = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1500,
    // cssEase: "linear",
    pauseOnHover: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
  };
  return (
    <div id="clients">
      <div style={{textAlign:"center", overflow: "hidden",marginBottom:"50px"}}>
      <h2 className="title mb-5 pt-5">شركاء النجاح</h2>

        <Slider {...settings}>
          <div className="slider-info">
            <img src={client1} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client2} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client3} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client4} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client5} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client6} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client7} alt="client" />
          </div>

          <div className="slider-info">
            <img src={client8} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client9} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client10} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client11} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client12} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client13} alt="client" />
          </div>
          <div className="slider-info">
            <img src={client14} alt="client" />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default OurClients;
