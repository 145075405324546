import React from "react";

const HeroSocail = () => {
  return (
    <>
      <div className="hero-social">
        <div>
          <a rel="noreferrer" target="_blank" href="tel:01111149544">
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/Elagouz.Hospital/"
          >
            <i className="fab fa-facebook"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default HeroSocail;
