import React  from "react";
import { Link } from "react-router-dom";

// Import Images
import logo from "../../images/logo.svg";

import { useSelector } from "react-redux";
const FormLogin = () => {
	const lang = useSelector((state) => state.lang.value.lang);
	const main = useSelector((state) => state.lang.value.loginPage);
  return (
    <>
      <div className="section-area account-wraper2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <div className="appointment-form form-wraper">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
                <form action="#">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={main.username[lang]}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder={main.password[lang]}
                    />
                  </div>
                  <div className="form-group">
                    <Link
                      to="/"
                      type="button"
                      className="btn mb-30 btn-lg btn-primary w-100"
                    >
                      {main.loginBtn[lang]}
                    </Link>
                    <Link to="/form-forget-password" data-toggle="tab">
                     {main.forgotPassword[lang]}
                    </Link>
                  </div>
                  <div className="text-center mt-40">
                    <p className="mt-0">{main.text[lang]}</p>
                    <Link
                      className="btn btn-lg btn-secondary w-100"
                      data-toggle="tab"
                      to="/form-register"
                    >
                      {main.registerBtn[lang]}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormLogin;
