import React from "react";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
// Import Images
import trangleOrange from "../../images/shap/trangle-orange.png";
import squareDotsOrange from "../../images/shap/square-dots-orange.png";
import lineCircleBlue from "../../images/shap/line-circle-blue.png";
import waveBlue from "../../images/shap/wave-blue.png";
import plusBlue from "../../images/shap/circle-dots.png";
import teamMember1 from "../../images/team/member1.jpg";
import teamMember2 from "../../images/team/member2.jpg";
import teamMember3 from "../../images/team/member3.jpg";
import teamMember4 from "../../images/team/member4.jpg";
import teamMember5 from "../../images/team/member5.jpg";
import teamMember6 from "../../images/team/member6.jpg";

import { useSelector } from "react-redux";
import HeroPage from "../elements/heroPage";
const Team = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const main = useSelector((state) => state.lang.value.teamPage);
  const { ourTeam } = useSelector((state) => state.lang.value.pages);
  const content = [
    {
      thumb: teamMember1,
      title: main.card1.title[lang],
      subtitle:main.card1.text[lang],
    },
    {
      thumb: teamMember2,
      title: main.card2.title[lang],
      subtitle: main.card2.text[lang],
    },
    {
      thumb: teamMember3,
      title: main.card3.title[lang],
      subtitle: main.card3.text[lang],
    },
    {
      thumb: teamMember4,
      title: main.card4.title[lang],
      subtitle: main.card4.text[lang],
    },
    {
      thumb: teamMember5,
      title: main.card5.title[lang],
      subtitle: main.card1.text[lang],
    },
    {
      thumb: teamMember6,
      title: main.card6.title[lang],
      subtitle: main.card6.title[lang],
    },
  ];
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <HeroPage text={ourTeam[lang]}/>

        <section className="section-area section-sp1 team-wraper">
          <div className="container">
            <div className="row">
              {content.map((item, index) => (
                <div className="col-lg-4 col-sm-6" key={index}>
                  <div className="team-member mb-30">
                    <div className="team-media">
                      <img src={item.thumb} alt="" />
                    </div>
                    <div className="team-info">
                      <div className="team-info-comntent">
                        <h4 className="title">{item.title}</h4>
                        <span className="text-secondary">{item.subtitle}</span>
                      </div>
                      <ul className="social-media">
                        <li>
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://twitter.com/"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.linkedin.com/"
                          >
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.instagram.com/"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img className="pt-img1 animate1" src={trangleOrange} alt="" />
          <img className="pt-img2 animate2" src={squareDotsOrange} alt="" />
          <img className="pt-img3 animate-rotate" src={lineCircleBlue} alt="" />
          <img className="pt-img4 animate-wave" src={waveBlue} alt="" />
          <img className="pt-img5 animate-wave" src={plusBlue} alt="" />
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Team;
