import React from "react";
import { Formik } from "formik";
// Images
import lineBg from "../../images/appointment/line-bg.png";
import appMobile from "../../images/appointment/mobile.png";
import appWomen from "../../images/appointment/women.png";
import appMapPin from "../../images/appointment/map-pin.png";
import appSetting from "../../images/appointment/setting.png";
import appCheck from "../../images/appointment/check.png";
import appChat from "../../images/appointment/chat.png";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/wave-orange.png";
import ptImg3 from "../../images/shap/wave-blue.png";
import ptImg4 from "../../images/shap/circle-orange.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getServices,
  getOpen,
  getProviders,
  bookAppoint,
  clearValue,
} from "../../store/book/bookSlice";
import { useState } from "react";
import * as yup from "yup";

const Appointment = () => {
  //---- selecor
  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.appointmentSection);
  const { services, provider, open, errorsMsg } = useSelector(
    (state) => state.book
  );

  //--- intitalstate
  const [service, setService] = useState("");
  const [doctor, setDoctor] = useState("");
  const initialValues = {
    serviceid: "",
    providerid: "",
    name: "",
    phone: "",
    open: "",
  };

  //---- Dispatch
  const dispatch = useDispatch();

  const handleFormSubmit = (values, { resetForm }) => {
    const { serviceid, providerid, name, phone, open } = values;
    dispatch(
      bookAppoint({ data: { serviceid, providerid, name, phone }, open })
    );
    resetForm({ values: "" });
  };

  //---- On Change
  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const checkoutSchema = yup.object().shape({
    name: yup.string().required(lang === "ar" ? "مطلوب": "required"),
    serviceid: yup.string().required(lang === "ar" ? "مطلوب": "required"),
    phone: yup
      .string()
      .matches(phoneRegExp, lang === "ar" ? "رقم الهاتف غير صالح": "Phone number is not valid") // "
      .required(lang === "ar" ? "مطلوب": "required"),
    providerid: yup.string().required(lang === "ar" ? "مطلوب": "required"),
    open: yup.string().required(lang === "ar" ? "مطلوب": "required"),
  });

  return (
    <>
      <section className="section-area account-wraper1" id="booking">
        <div className="container-fluid">
          <div
            className="appointment-inner section-sp2"
            style={{
              backgroundImage: "url(" + lineBg + ")",
              backgroundRepeat: " no-repeat",
              backgroundPosition: "20px 140px",
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-6 col-md-6">
                  <div className="appointment-form form-wraper">
                    <h3 className="title">حجز العيادات الخارجية</h3>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={handleFormSubmit}
                      validationSchema={checkoutSchema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <select
                              className="form-select form-control"
                              onChange={(e) => {
                                setFieldValue("serviceid", e.target.value);
                                dispatch(getProviders(e.target.value));
                                setService(e.target.value);
                                console.log("provider", provider);
                              }}
                              onBlur={handleBlur}
                              value={values.serviceid}
                              name="serviceid"
                            >
                              <option defaultValue>
                                {/* {main.selectDepartment.title['ar']} */}
                                حدد التخصص
                              </option>

                              {services?.map((ser) => (
                                <option value={ser.id} key={ser.id} id={ser.id}>
                                  {ser.title}
                                </option>
                              ))}
                            </select>
                            <div className="error text-end">
                              {errors.serviceid &&
                                touched.serviceid &&
                                errors.serviceid}
                            </div>
                          </div>
                          <div className="form-group">
                            <select
                              className="form-select form-control"
                              disabled={service ? false : true}
                              onChange={(e) => {
                                setFieldValue("providerid", e.target.value);
                                dispatch(clearValue());
                                dispatch(
                                  getOpen({ service, doctor: e.target.value })
                                );
                                setDoctor(e.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.providerid}
                              name="providerid"
                            >
                              <option defaultValue>
                                اختر استشارى/ أخصائى
                              </option>
                              {provider?.map((doc) => {
                                return (
                                  <option value={doc?.id}>
                                    {doc?.provider?.user?.first_name}{" "}
                                    {doc?.provider?.user?.last_name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="error text-end">
                              {errors.providerid &&
                                touched.providerid &&
                                errors.providerid}
                            </div>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={main.name['ar']}
                              onBlur={handleBlur}
                              value={values.name}
                              name="name"
                              onChange={(e) =>
                                setFieldValue("name", e.target.value)
                              }
                            />
                            <div className="error text-end">
                              {errors.name && touched.name && errors.name}
                            </div>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={main.phone['ar']}
                              onBlur={handleBlur}
                              value={values.phone}
                              name="phone"
                              onChange={(e) =>
                                setFieldValue("phone", e.target.value)
                              }
                            />
                            <div className="error text-end">
                              {errors.phone && touched.phone && errors.phone}
                            </div>
                          </div>
                          {open?.length > 0 ? (
                            <div className="form-group">
                              <select
                                className="form-select form-control"
                                onChange={(e) => {
                                  setFieldValue("open", e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.open}
                                name="open"
                              >
                                <option defaultValue>
                                  {main.selectDoctor.selectDoctor['ar']}
                                </option>
                                {open?.map((opt) => {
                                  return (
                                    <option value={opt.id}>
                                      {new Date(opt.date).toLocaleTimeString(
                                        "ar-EG",
                                        {
                                          weekday: "long",
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        }
                                      )}
                                    </option>
                                  );
                                })}
                              </select>
                              <div className="error text-end">
                                {errors.open && touched.open && errors.open}
                              </div>
                            </div>
                          ) : errorsMsg.open !== "" ? (
                            <div className="error text-end">
                              {errorsMsg.open}
                            </div>
                          ) : (
                            ""
                          )}

                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-secondary btn-lg"
                          >
                            {main.button['ar']}
                          </button>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 col-md-6">
                  <div className="appointment-thumb">
                    <img src={appMobile} alt="" />
                    <div className="images-group">
                      <img className="img1" src={appWomen} alt="" />
                      <img className="img2" src={appMapPin} alt="" />
                      <img className="img3" src={appSetting} alt="" />
                      <img className="img4" src={appCheck} alt="" />
                      <img className="img5" src={appChat} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="pt-img1 animate1" src={ptImg1} alt="" />
            <img className="pt-img2 animate-wave" src={ptImg2} alt="" />
            <img className="pt-img3 animate-wave" src={ptImg3} alt="" />
            <img className="pt-img4 animate2" src={ptImg4} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Appointment;
