import React from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import testPic1 from "../../images/testimonials/pic1.jpg";
import testPic2 from "../../images/testimonials/pic2.jpg";
import testPic3 from "../../images/testimonials/pic3.jpg";
import testPic4 from "../../images/testimonials/pic4.jpg";
import testPic5 from "../../images/testimonials/pic5.jpg";
import testPic6 from "../../images/testimonials/pic6.jpg";
import blogGridPic1 from "../../images/blog/grid/pic1.jpg";
import blogGridPic2 from "../../images/blog/grid/pic2.jpg";
import blogGridPic3 from "../../images/blog/grid/pic3.jpg";
import blogGridPic4 from "../../images/blog/grid/pic4.jpg";
import blogGridPic5 from "../../images/blog/grid/pic5.jpg";
import blogGridPic6 from "../../images/blog/grid/pic6.jpg";
import blogGridPic7 from "../../images/blog/grid/pic7.jpg";
import blogGridPic8 from "../../images/blog/grid/pic8.jpg";
import blogGridPic9 from "../../images/blog/grid/pic9.jpg";

import HeroPage from "../elements/heroPage";

// Blog Content
import { useSelector } from "react-redux";

const BlogGrid = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const main = useSelector((state) => state.lang.value.blogPage);
  const { blog } = useSelector((state) => state.lang.value.pages);

  const content = [
    {
      thumb: blogGridPic1,
      authorPic: testPic1,
      title: main.card1.heading[lang],
      author: main.card1.author[lang],
      date: main.card1.date[lang],
    },
    {
      thumb: blogGridPic2,
      authorPic: testPic2,
      title: main.card2.heading[lang],
      author: main.card2.author[lang],
      date: main.card2.date[lang],
    },
    {
      thumb: blogGridPic3,
      authorPic: testPic3,
      title: main.card3.heading[lang],
      author: main.card3.author[lang],
      date: main.card3.date[lang],
    },
    {
      thumb: blogGridPic4,
      authorPic: testPic4,
      title: main.card4.heading[lang],
      author: main.card4.author[lang],
      date: main.card4.date[lang],
    },

    {
      thumb: blogGridPic6,
      authorPic: testPic6,
      title: main.card5.heading[lang],
      author: main.card5.author[lang],
      date: main.card5.date[lang],
    },
    {
      thumb: blogGridPic7,
      authorPic: testPic1,
      title: main.card6.heading[lang],
      author: main.card6.author[lang],
      date: main.card6.date[lang],
    },
    {
      thumb: blogGridPic8,
      authorPic: testPic2,
      title: main.card7.heading[lang],
      author: main.card7.author[lang],
      date: main.card7.date[lang],
    },
    {
      thumb: blogGridPic9,
      authorPic: testPic3,
      title: main.card8.heading[lang],
      author: main.card8.heading[lang],
      date: main.card8.heading[lang],
    },
    {
      thumb: blogGridPic5,
      authorPic: testPic5,
      title: main.card9.heading[lang],
      author: main.card9.author[lang],
      date: main.card9.date[lang],
    },
  ];
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <HeroPage text={blog[lang]} />

        <section className="section-area section-sp1">
          <div className="container">
            <div className="row">
              {content.map((item, index) => (
                <div className="col-xl-4 col-md-6" key={index}>
                  <div className="blog-card mb-30">
                    <div className="post-media">
                      <Link to="/blog-details">
                        <img src={item.thumb} alt="" />
                      </Link>
                    </div>
                    <div className="post-info">
                      <ul className="post-meta">
                        <li className="author">
                          <Link to="/blog-details">
                            <img src={item.authorPic} alt="" /> {item.author}
                          </Link>
                        </li>
                        <li className="date">
                          <i className="far fa-calendar-alt"></i> {item.date}
                        </li>
                      </ul>
                      <h4 className="post-title">
                        <Link to="/blog-details">{item.title}</Link>
                      </h4>
                      <Link
                        to="/blog-details"
                        className="btn btn-outline-primary btn-sm"
                      >
                        {lang === 'ar' && <i className="btn-icon-bx fas fa-chevron-right"></i> }
                        {main.card1.button[lang]}{" "}
                        {lang === 'en' && <i className="btn-icon-bx fas fa-chevron-right"></i> }
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="pagination-bx text-center mb-30 clearfix">
                  <ul className="pagination">
                    <li className="previous">
                      <Link to="#">Prev</Link>
                    </li>
                    <li className="active">
                      <Link to="#">1</Link>
                    </li>
                    <li>
                      <Link to="#">2</Link>
                    </li>
                    <li>
                      <Link to="#">3</Link>
                    </li>
                    <li className="next">
                      <Link to="#">Next</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default BlogGrid;
