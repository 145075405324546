import React from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import '@fortawesome/fontawesome-free/css/all.min.css';
// Images
// import logo from "../../images/logo.svg";
import logo from "../../images/logo.png";
import LangBtn from "../elements/langBtn";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Header = ({ color, services }) => {
  const lang = "ar";
  const pages = useSelector((state) => state.lang.value.pages);

  const currentRoute = useHistory().location.pathname;
  const hash = useHistory().location.hash;

  useEffect(() => {
    var searchBtn = document.getElementById("quikSearchBtn");
    var searchForm = document.querySelector(".nav-search-bar");
    var closeBtn = document.getElementById("searchRemove");

    // open and close navbar
    searchBtn.addEventListener("click", function () {
      searchForm.classList.add("show");
    });

    closeBtn.addEventListener("click", function () {
      searchForm.classList.remove("show");
    });

    // Mobile Menu Open
    var menuIcon = document.querySelector(".menuicon");
    var menuLinks = document.querySelector(".menu-links");
    var menuClose = document.getElementById("menuClose");

    menuIcon.addEventListener("click", function () {
      menuLinks.classList.add("show");
    });

    menuClose.addEventListener("click", function () {
      menuLinks.classList.remove("show");
    });

    // Mobile Submenu open close function
    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener("click", function () {
        menuClick(this);
      });
    }

    function menuClick(current) {
      const active = current.classList.contains("open");
      navMenu.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
        console.log("active");
      } else {
        current.classList.add("open");
      }
    }
  });
  return (
    <>
      <header className='header header-transparent rs-nav '>
        <Sticky enabled={true} className="sticky-header navbar-expand-lg" >
          <div className="menu-bar clearfix">
            <div className="container-fluid clearfix">
              <div className="menu-logo logo-dark">
                <Link to="/">
                  <img src={logo} alt="" style={{ width: "80px" }} />
                </Link>
              </div>
              <button
                className="navbar-toggler collapsed menuicon justify-content-end"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#menuDropdown"
                aria-controls="menuDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className="secondary-menu">
                <ul>
                  <li className="search-btn">
                    <button
                      id="quikSearchBtn"
                      type="button"
                      className="btn-link"
                    >
                      <i className="las la-search"></i>
                    </button>
                  </li>
                  <li className="num-bx">
                    <a href="tel:01111149544">
                      <i className="fas fa-phone-alt"></i>01111149544
                    </a>
                  </li>
                  <li className="btn-area">
                    <Link to="/contact-us" className="btn btn-primary shadow">
                      {pages.contact[lang]}{" "}
                      {lang === "en" && (
                        <i className="btn-icon-bx fas fa-chevron-right"></i>
                      )}
                    </Link>
                  </li>
                  <li className="btn-area">
                    <Link to="/family-cart" className="btn btn-primary shadow">
                      كارت العيله
                      {lang === "en" && (
                        <i className="btn-icon-bx fas fa-chevron-right"></i>
                      )}
                    </Link>
                  </li>
                  {/* <LangBtn /> */}
                </ul>
              </div>
              <div
                className="menu-links navbar-collapse collapse justify-content-end"
                id="menuDropdown"
              >
                <div className="menu-logo">
                  <Link to="/">
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <ul className="nav navbar-nav" >
                  <li
                    className={
                      currentRoute === "/" && hash === "" ? "active" : undefined
                    }
                  >
                    <Link to="/">{pages.home[lang]}</Link>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <Link className={`nav-link dropdown-toggle ${color}`} to="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" dir="ltr">
                      من نحن
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-light " aria-labelledby="navbarDarkDropdownMenuLink" >
                      <li className="text-end"><Link className="dropdown-item text-dark" to="/see">الرؤيا</Link></li>
                      <li className="text-end"><Link className="dropdown-item text-dark" to="/goal">الهدف</Link></li>
                      <li className="text-end"><Link className="dropdown-item text-dark" to="/message">الرساله</Link></li>
                    </ul>
                  </li> */}
                  <li
                    className={
                      currentRoute ==="/see" || currentRoute === "/goal" || currentRoute === "/message" ? "active" : "nav-item"
                    }>
                    <Link to="#" className="nav-link"><i class="fa-solid fa-caret-down"></i>من نحن</Link>
                    <ul className="sub-menu">
                      <li className="add-menu-left">
                        <ul >
                          <li>
                            <Link to="/see">
                              الرؤيا
                            </Link>
                          </li>
                          <li>
                            <Link to="/goal">
                              <span> الهدف </span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/message">
                              <span> الرساله </span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  {/* <li className={hash === "#services" ? "active " : undefined}>
                    <a href="/#services" className={`${services} `} >{pages.services[lang]}</a>
                  </li> */}
                  <li
                    className={
                      hash === "#services" ||currentRoute==="/service-detail-outpatient-clinic"||
                        currentRoute === "/service-detail-emergency" || currentRoute==="/service-detail-surgery" || currentRoute==="/service-detail-lab"|| currentRoute==="/service-detail-woman"||currentRoute==="/service-detail-baby"||currentRoute==="/service-detail-boon"||currentRoute==="/service-detail-urinary-tract"||currentRoute==="/service-detail-physical-therapy"||currentRoute==="/service-detail-diagnostic-x-ray"
                        ? "active"
                        : undefined
                    }
                  >
                    <a href="/#services" className={`${services} `}>

                      <i class="fa-solid fa-caret-down"></i>{pages.services[lang]}
                    </a>
                    <ul className="sub-menu">
                      <li className="add-menu-left">
                        <ul className=" overflow-auto" style={{ maxHeight: '70vh' }}>
                          <li>
                            <Link to="/service-detail-emergency">
                              خدمات الطوارئ
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-surgery">
                              <span>الجراحة وغرف العمليات</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-outpatient-clinic">
                              <span>العيادات الخارجية</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-woman">
                              <span>النسا والولادة وعلاج العقم وتأخر الانجاب</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-baby">
                              <span>طب الاطفال وحديثى الولادة</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-boon">
                              <span>جراحة العظام</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-urinary-tract">
                              <span>المسالك البولية</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-physical-therapy">
                              <span>العلاج الطبيعى</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-diagnostic-x-ray">
                              <span>اﻷشعة التشخيصية</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/service-detail-lab">
                              <span>المعمل</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  {/* <ul className="dropdown-menu dropdown-menu-light " aria-labelledby="navbarDarkDropdownMenuLinkk" dir="rtl">
                    <li className="text-end"><Link className="dropdown-item text-dark" to="/service-detail-emergency">الطوارئ</Link></li>
                    <li className="text-end"><Link className="dropdown-item text-dark" to="/service-detail-surgery">الجراحه والعمليات</Link></li>
                    <li className="text-end"><Link className="dropdown-item text-dark" to="/service-detail-baby">أطفال</Link></li>
                  </ul> */}
                  <li className={hash === "#booking" ? "active" : undefined}>
                    <HashLink smooth to="/#booking">حجز العيادات الخارجية</HashLink>
                  </li>


                  <li className={hash === "#team" ? "active" : undefined}>
                    <a href="/#team">{pages.ourTeam[lang]}</a>
                  </li>
                  <li className={hash === "#latest" ? "active" : undefined}>
                    <a href="/#latest">أخبارنا</a>
                  </li>
                  {/* <li>
                    <Link to="#">
                      {pages.blog[lang]} <i className="fas fa-plus"></i>
                    </Link>
                    <ul className="sub-menu left">
                      <li>
                        <Link to="/blog-grid">
                          <span>{pages.blog[lang]}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-details">
                          <span>{pages.blogDetails[lang]}</span>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  <li className={hash === "#clients" ? "active" : undefined}>
                    <a href="/#clients">شركاء النجاح</a>
                  </li>
                  <li className={hash === "#contact" ? "active" : undefined}>
                    <a href="/#contact"> تواصل معنا</a>
                  </li>
                </ul>
                <ul className="social-media">
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.google.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-google"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/"
                      className="btn btn-primary"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
                <div className="menu-close" id="menuClose">
                  <i className="ti-close"></i>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
        <div className="nav-search-bar">
          <form>
            <input
              type="text"
              className="form-control"
              placeholder="Type to search"
            />
            <span>
              <i className="ti-search"></i>
            </span>
          </form>
          <span id="searchRemove">
            <i className="ti-close"></i>
          </span>
        </div>
      </header>
    </>
  );
};

export default Header;
