import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Import Images
import servicesPic1 from "../../images/Physiotherapy-Rooms.jpg";
import pdf from "../../images/icon/pdf.png";
import doc from "../../images/icon/doc.png";
import HeroPage from "../elements/heroPage";
import { useSelector } from "react-redux";

const ServicesDetailsMasalk = () => {

    const lang = 'ar';
    const main = useSelector((state) => state.lang.value.servicesDetails);
    const mainRight = useSelector((state) => state.lang.value.servicesDetailsRight);
    const { servicesDetails } = useSelector((state) => state.lang.value.pages)

    return (
        <>
            <Header />

            <div className="page-content bg-white">
                <HeroPage text={servicesDetails[lang]} />

                <section className="section-area section-sp1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-30">
                                <div className="ttr-media mb-30 ">
                                    <img src={servicesPic1} className="rounded w-full" alt="" />
                                </div>
                                <div className="clearfix">
                                    <div className="head-text mb-30">
                                        <h2 className="title mb-15">
                                            قسم المسالك البولية
                                        </h2>
                                        <p className="">
                                            ‫يعمل ‬‫بقسم‬ ا‫لمسالك‬ ‫البولية‬ ‫مجموعة‬ ‫استشاريين‬ ‫من‬ ‫نخبة‬ ‫اﻷطباء‬ ‫و‬ ‫يستقبل‬ ‫المرضى‬ ‫يوميا‬ ‫و‬ ‫يتوفر‬ ‫بالقسم‬ ‫العمليات‬ ‫و‬
                                            ‫الجراحات ‬‫و‬ ‫المناظير‬ ‫الحديثة‬ ‫لجراحات‬ ‫الكلى‬ ‫والمسالك‬ ‫البولية‬ ‫‪.‬‬ ‫و‬ ‫ايضا‬ ‫ًًَ‬ ‫قسم‬ ‫التفتيت‬ ‫لتفتيت‬ ‫الحصوات‬ ‫المختلفة.‬ ‫‪‬‬    <br />
                                            ‫مميزات ‬‫قسم‬ ‫المسالك‬ ‫البولية‬ ‫ب‬‫مستشفى‬‫ العجوز‬ ‫التخصصى‬ ‫‪:‬‬
                                        </p>

                                        <ul>
                                            <li>‫قسم ‬‫العيادات‬ ‫يضم‬ ‫جهاز‬ ‫موجات‬ ‫فوق‬ ‫الصوتية‬ ‫للكشف‬ ‫و‬ ‫اخذ‬ ‫عينات‬ ‫من‬ ‫البروستاتا‬ ‫‪.‬‬</li>
                                            <li>‫قسم ‬‫الجراحة‬ ‫و‬ ‫المناظير‬ ‫و‬ ‫يضم‬ ‫احدث‬ ‫المناظير‬ ‫الضوئية‬ ‫‪.‬‬</li>
                                            <li>‫قسم‬‫ التفتيت‬ ‫لتفتيت‬ ‫الحصوات‬ ‫بدون‬ ‫الم‬ ‫‪.‬‬</li>
                                        </ul>


                                        <p className="">‫تفهم ‬‫أخصائيو‬ ‫قسم‬ ‫المسالك‬ ‫البولية‬ ‫لدينا‬ ‫طبيعة‬ ‫العوائق‬ ‫النفسية‬ ‫المرتبطة‬ ‫بمرضى‬ ‫المسالك‬ ‫البولية‬ ‫و‬ ‫التي‬ ‫تمنع‬ ‫معظم‬ ‫المرضى‬ ‫من‬ ‫التعبير‬ ‫عن‬
                                            ‫قلقهم ‬‫و‬‫ابداء‬ ‫الرغبة‬ ‫فى‬ ‫العلاج‪.‬‬ ‫اما‬ ‫فى‬ ‫مستشفى‬ ‫سيتي‬ ‫يتميز‬ ‫اطباء‬ ‫مستشفى‬ ‫سيتي‬ ‫بالمهارة‬ ‫والخبرة‬ ‫المطلوبة‬ ‫في‬ ‫التعامل‬ ‫بحذر‬ ‫مع‬ ‫هذه‬ ‫اﻷمور‪،‬‬
                                            ‫و‬‫نطمأن‬ ‫مرضانا‬ ‫دايما‬ ‫مما‬ ‫يسمح‬ ‫لنا‬ ‫بالكشف‬ ‫المبكر‬ ‫عن‬ ‫اﻷمراض‪.‬‬ ‫يشمل‬ ‫القسم‬ ‫كوادر‬ ‫طبية‬ ‫كبيرة‬ ‫في‬ ‫التخصصات‬ ‫الفرعية‬ ‫لطب‬ ‫المسالك‬
                                            ‫البولية‪،‬‬‫ويوظّف‬ ‫أساليب‬ ‫عالجية‬ ‫حديثة‬ ‫للسيطرة‬ ‫على‬ ‫اﻷمراض‪،‬‬ ‫بما‬ ‫في‬ ‫ذلك‬ ‫استخدام‬ ‫كل‬ ‫من‬ ‫اﻷسلوب‬ ‫الطبي‬ ‫والجراحي‬ ‫لعلاج‬ ‫سرطان‬ ‫المسالك‬
                                            ‫البولية ‬‫وأمراض‬ ‫البروستات‬ ‫اﻷخرى‬ ‫و‬ ‫ايضا‬ ‫علاج‬ ‫أمراض‬ ‫المسالك‬ ‫البولية‬ ‫النسائية‬ ‫والسيطرة‬ ‫الشاملة‬ ‫على‬ ‫حصى‬ ‫الكلى‬ ‫و‬ ‫حالات‬ ‫الضعف‬
                                            ‫الجنسي‬</p>

                                        <p className="fw-bold mb-0">‫و‬‫من‬ ‫الخدمات‬ ‫المتوفرة‬ ‫فى‬ ‫قسم‬ ‫المسالك‬ ‫البولية‪:‬‬</p>
                                        <ul>
                                            <li>‫علاج ‬‫أمراض‬ ‫البروستات‬</li>
                                            <li>‫علاج ‬‫سرطان‬ ‫المسالك‬ ‫البولية‬</li>
                                            <li>‫علاج ‬‫سلس‬ ‫البول‬</li>
                                            <li>‫خدمات ‬‫الرعاية‬ ‫الصحية‬ ‫للرجال‬</li>
                                            <li>‫علاج ‬‫أمراض‬ ‫المسالك‬ ‫البولية‬ ‫لدى‬ ‫اﻷطفال‬</li>
                                            <li>‫‫علاج‬‫ ضعف‬ ‫المثانة‬‬</li>
                                            <li>‫علاج ‬‫حصى‬ ‫الكلية‬ ‫بالموجات‬ ‫التصادمية‬ ‫أو‬ ‫بعلاجات‬ ‫المسالك‬ ‫البولية‬ ‫الداخلية‬‬</li>
                                            <li>‫السيطرة ‬‫على‬ ‫الضعف‬ ‫الجنسي‬</li>
                                        </ul>
                                       
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <aside className="sticky-top pb-1">
                                    <div className="widget">
                                        <ul className="service-menu">
                                            <li className="">
                                                <Link to="/service-detail-emergency">
                                                    <span>خدمات الطوارئ</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-surgery">
                                                    <span>الجراحة وغرف العمليات</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-outpatient-clinic">
                                                    <span>العيادات الخارجية</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-woman">
                                                    <span>النساء والولادة وعلاج العقم وتأخر الانجاب</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-baby">
                                                    <span>طب الاطفال وحديثى الولادة</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li >
                                                <Link to="/service-detail-boon">
                                                    <span>جراحة العظام</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li className="active">
                                                <Link to="/service-detail-urinary-tract">
                                                    <span>المسالك البولية </span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-physical-therapy">
                                                    <span>العلاج الطبيعى</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-diagnostic-x-ray">
                                                    <span>اشعة التشخيصية</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/service-detail-lab">
                                                    <span>المعمل</span>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="widget">
                    <div className="brochure-bx">
                      <h5 className="title-head">{mainRight.downloadHeading[lang]}</h5>
                      <Link to="#" className="download-link">
                        <img src={pdf} alt="" />
                        <h5 className="title">{mainRight.file1[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                      <Link to="#" className="download-link">
                        <img src={doc} alt="" />
                        <h5 className="title">{mainRight.file2[lang]}</h5>
                        <span>{mainRight.downloadHeading[lang]}</span>
                      </Link>
                    </div>
                  </div> */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </>
    );
};

export default ServicesDetailsMasalk;
