import React from "react";
import { Link } from "react-router-dom";

// Import Images
import recentBlogImg1 from "../../images/blog/recent-blog/pic1.jpg";
import recentBlogImg2 from "../../images/blog/recent-blog/pic2.jpg";
import recentBlogImg3 from "../../images/blog/recent-blog/pic3.jpg";

import { useSelector } from "react-redux";

const WidgetRecentPosts = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const {recentPages} = useSelector((state) => state.lang.value.blogDetailsRight);
  return (
    <>
      <div className="widget recent-posts-entry">
        <h4 className="widget-title">{recentPages.name[lang]}</h4>
        <div className="widget-post-bx">
          <div className="widget-post clearfix">
            <div className="ttr-post-media">
              {" "}
              <img src={recentBlogImg1} width="200" height="143" alt="" />{" "}
            </div>
            <div className="ttr-post-info">
              <div className="ttr-post-header">
                <h6 className="post-title">
                  <Link to="/blog-details">
				  	{recentPages.card1.heading[lang]}
                  </Link>
                </h6>
              </div>
              <ul className="post-meta">
                <li className="date">
                  <i className="far fa-calendar-alt"></i> {recentPages.card1.date[lang]}
                </li>
              </ul>
            </div>
          </div>
          <div className="widget-post clearfix">
            <div className="ttr-post-media">
              {" "}
              <img src={recentBlogImg2} width="200" height="160" alt="" />{" "}
            </div>
            <div className="ttr-post-info">
              <div className="ttr-post-header">
                <h6 className="post-title">
                  <Link to="/blog-details">
				  	{recentPages.card2.heading[lang]}
                  </Link>
                </h6>
              </div>
              <ul className="post-meta">
                <li className="date">
                  <i className="far fa-calendar-alt"></i> {recentPages.card2.date[lang]}
                </li>
              </ul>
            </div>
          </div>
          <div className="widget-post clearfix">
            <div className="ttr-post-media">
              {" "}
              <img src={recentBlogImg3} width="200" height="160" alt="" />{" "}
            </div>
            <div className="ttr-post-info">
              <div className="ttr-post-header">
                <h6 className="post-title">
                  <Link to="/blog-details">
				  	{recentPages.card3.heading[lang]}
                  </Link>
                </h6>
              </div>
              <ul className="post-meta">
                <li className="date">
                  <i className="far fa-calendar-alt"></i> {recentPages.card1.date[lang]}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WidgetRecentPosts;
