import React from "react";
import { Link } from "react-router-dom";

// Import Images
import testPic1 from "../../images/testimonials/pic1.jpg";
import testPic2 from "../../images/testimonials/pic2.jpg";

import { useSelector } from "react-redux";
const CommentList = () => {
  const lang = 'ar';
  const main = useSelector((state) => state.lang.value.blogDetails);
  return (
    <>
      <ol className="comment-list">
        <li className="comment">
          <div className="comment-body">
            <div className="comment-author vcard">
              <img className="avatar photo" src={testPic1} alt="" />
              <div className="clearfix">
                <cite className="fn">{main.comment1.author['ar']}</cite>
                <span className="says">says:</span>
                <div className="comment-meta">
                  {" "}
                  <Link to="#">{main.comment1.date['ar']}</Link>{" "}
                </div>
              </div>
            </div>
            <p>
              {main.comment1.text['ar']}
            </p>
            <div className="reply">
              {" "}
              <Link to="#" className="comment-reply-link">
                {main.replay.name['ar']}
              </Link>{" "}
            </div>
          </div>
          <ol className="children">
            <li className="comment odd parent">
              <div className="comment-body">
                <div className="comment-author vcard">
                  <img className="avatar photo" src={testPic2} alt="" />
                  <div className="clearfix">
                    <cite className="fn">{main.comment2.author['ar']}</cite>
                    <span className="says">says:</span>
                    <div className="comment-meta">
                      {" "}
                      <Link to="#">{main.comment2.date['ar']}</Link>
                    </div>
                  </div>
                </div>
                <p>
                  {main.comment2.text['ar']}
                </p>
                <div className="reply">
                  {" "}
                  <Link to="#" className="comment-reply-link">
                    {main.replay.name['ar']}
                  </Link>
                </div>
              </div>
            </li>
          </ol>
        </li>
        <li className="comment">
          <div className="comment-body">
            <div className="comment-author vcard">
              <img className="avatar photo" src={testPic1} alt="" />
              <div className="clearfix">
                <cite className="fn">{main.comment3.author['ar']}</cite>
                <span className="says">says:</span>
                <div className="comment-meta">
                  {" "}
                  <Link to="#">{main.comment3.date['ar']}</Link>{" "}
                </div>
              </div>
            </div>
            <p>
              {main.comment3.text['ar']}
            </p>
            <div className="reply">
              {" "}
              <Link to="#" className="comment-reply-link">
                {main.replay.name['ar']}
              </Link>{" "}
            </div>
          </div>
        </li>
        <li className="comment">
          <div className="comment-body">
            <div className="comment-author vcard">
              <img className="avatar photo" src={testPic2} alt="" />
              <div className="clearfix">
                <cite className="fn">{main.comment4.author['ar']}</cite>
                <span className="says">says:</span>
                <div className="comment-meta">
                  {" "}
                  <Link to="#">{main.comment4.date['ar']}</Link>{" "}
                </div>
              </div>
            </div>
            <p>
             {main.comment4.text['ar']}.
            </p>
            <div className="reply">
              {" "}
              <Link to="#" className="comment-reply-link">
                {main.replay.name['ar']}  
              </Link>{" "}
            </div>
          </div>
        </li>
      </ol>
    </>
  );
};

export default CommentList;
