import React  from 'react';
import { useState, useEffect } from 'react';

const BackTop = () => {
    const [showBtn, setShowBtn] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowBtn(true);
            } else {
                setShowBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
  return (
    <>
				{showBtn && (
                    <button className="back-to-top" type="button" onClick={goToTop}>
						<i className="fa fa-chevron-up"></i>
                    </button>
                )}
			</>
  )
}

export default BackTop 